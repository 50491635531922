<template>
  <div
    v-for="(item, index) in projects"
    :key="item.id"
    style="margin: 10px"
  >
    <Card>
      <!--      <template #header>-->
      <!--        <img alt="user header" src="demo/images/usercard.png">-->
      <!--      </template>-->
      <template #title>
        {{ item.name }}
      </template>
      <template #content>
        {{ item.description }}

        <!--        <div v-for="item2 in item.metrics" :key="item2.id" style="margin: 10px">-->
        <!--          {{item2.name}}-->
        <!--        </div>-->
      </template>
      <template #footer>
        <div class="grid">
          <!--          <div class="mx-1 my-1" v-if="is_admin">-->
          <div class="mx-1 my-1">
            <Button
              icon="pi pi-chart-line"
              label="Метрики"
              class="p-button-outlined"
              style="width: 100%"
              @click="editMetrics(item.name,item.id)"
            />
          </div>
          <div class="mx-1 my-1">
            <Button
              icon="pi pi-clock"
              label="Отчетные периоды"
              class="p-button-outlined"
              style="width: 100%"
              @click="editPeriods(item.name,item.id)"
            />
          </div>

          <div
            v-if="is_logged_in"
            class="mx-1 my-1"
          >
            <Button
              icon="pi pi-pencil"
              label="Ввод данных"
              class="p-button-outlined"
              style="width: 100%"
              @click="editMetricValues(index)"
            />
          </div>
          <div class="mx-1 my-1">
            <Button
              icon="pi pi-list"
              label="Справочник"
              class="p-button-outlined"
              style="width: 100%"
              @click="viewProjectReport(index)"
            />
          </div>
          <div class="mx-1 my-1">
            <Button
              icon="pi pi-sort-alt-slash"
              label="Сводные данные"
              class="p-button-outlined"
              style="width: 100%"
              @click="viewProjectResults(index)"
            />
          </div>

          <div class="mx-1 my-1">
            <Button
              icon="pi pi-chart-pie"
              label="Анализ по трекам"
              class="p-button-outlined"
              style="width: 100%"
              @click="viewTracks(index)"
            />
          </div>
          <div class="mx-1 my-1">
            <Button
              badge-class="p-badge-info"
              icon="pi pi-percentage"
              label="Карта Шухарта"
              class="p-button-outlined"
              style="width: 100%"
              @click="viewShukhart(index)"
            />
          </div>
          <div class="mx-1 my-1">
            <Button
              icon="pi pi-chart-bar"
              label="Графики"
              class="p-button-outlined"
              style="width: 100%"
              @click="viewChartDialog(item.id,item.name)"
            />
          </div>
          <div
            v-if="is_logged_in"
            class="mx-1 my-1"
          >
            <Button
              icon="pi pi-file-word"
              label="Отчет"
              class="p-button-outlined"
              style="width: 100%"
              @click="getReport(index)"
            />
          </div>
          <div
              v-if="is_admin"
              class="mx-1 my-1"
          >
            <Button
                badge="new"
                badge-class="p-badge-info"
                icon="pi pi-file-excel"
                label="Экспорт данных"
                class="p-button-outlined"
                style="width: 100%"
                @click="getExcel(index)"
            />
          </div>
            <div
                v-if="is_admin"
                class="mx-1 my-1"
            >
            <Button
              badge="new"
              badge-class="p-badge-info"
              icon="pi pi-file-excel"
              label="Экспорт комментариев"
              class="p-button-outlined"
              style="width: 100%"
              @click="getCommentsExcel(index)"
          />
          </div>
          <!--          <div class="mx-1 my-1">-->
          <!--            <a target="new" href="http://monitoring.surgut.digital:3000/public/dashboard/8368f1af-b52a-40ef-b83a-964a42caa185">-->
          <!--              <Button icon="pi pi-desktop" label="Дашборд" class="p-button-outlined" style="width: 100%" />-->
          <!--            </a>-->
          <!--          </div>-->
        </div>
      </template>
    </Card>
  </div>

  <Metrics
    :title="projectName"
    :metrics="projects[currentProjectID]"
    :dialog-visible="metricsDialogVisible"
  />
  <ProjectChart
    :project="projects.filter(e => e.id == currentProjectID)[0]"
    :dialog-visible="chartDialogVisible"
  />
</template>

<script>

import Card from 'primevue/card';
import Button from "primevue/button";
import Metrics from "@/components/Metrics_old";
import router from "@/router";
import ProjectChart from "@/components/ProjectChart";
import store from "@/state";

export default {
  name: "ProjectsData",
  components: {Metrics, ProjectChart, Card, Button},
  data() {
    return {
      currentProjectID: '',
      metricsDialogVisible: '',
      chartDialogVisible: '',
      projectName: '',
    }
  },
  computed:{
    projects(){
      return store.state.projects;
    },
    is_logged_in()
        {
          return this.$store.state.loggedIn
        },
    is_admin(){
      return store.state.user.is_admin;
    },

    is_manager(){
      return store.state.user.is_manager;
    },
  },

  mounted() {
  },
  methods: {
    // viewMetricsDialog(name, id) {
    //   this.metricsDialogVisible = !this.metricsDialogVisible;
    //   this.projectName = name;
    //   this.currentProjectID = id;
    //   console.log(name, id)
    //  },
    editMetrics(name, id) {
      router.push('/metrics/' + id)
    },
    editPeriods(name, id) {
      router.push('/periods/' + id)
    },
    viewChartDialog(id, name) {
      this.chartDialogVisible = !this.chartDialogVisible;
      this.currentProjectID = id;
      ProjectChart.data().selectedMetric = '';
      ProjectChart.data().selectedArea = '';
      ProjectChart.data().basicData = [];
      console.log(id, name)
      console.log( ProjectChart.data().basicData)
      console.log(id, name)
    },
    viewProjectReport(id) {
      router.push('/projectreport/' + this.projects[id].id)
    },
    editMetricValues(id) {
      router.push('/editmetricvalues/' + this.projects[id].id)
    },
    viewProjectResults(id) {
      router.push('/projectresults/' + this.projects[id].id)
    },
    viewTracks(id) {
      router.push('/tracks/' + this.projects[id].id)
    },
    viewShukhart(id) {
      router.push('/shukhart/' + this.projects[id].id)
    },
    getReport(id) {
      router.push('/report/' + this.projects[id].id)
    },
    getExcel(id) {
      store.dispatch('getExcel', [this.projects[id].id])
          .then(() => {
                console.log('Данные в Excel загружены');
              }
          )
    },
    getCommentsExcel(id) {
      store.dispatch('getCommentsExcel', [this.projects[id].id])
          .then(() => {
                console.log('Комментарии в Excel загружены');
              }
          )
    },
  },
}
</script>

<style>
  /*.layout{*/
  /*  margin: 10px;*/
  /*}*/
</style>
