<template>
  <div id="app">
    <!--    <Menu></Menu>-->
    <SignIn />
    <router-view :key="$route.fullPath" />
    <Card class="text-center">
      <template #content>
        (c) 2024 Сургутский государственный университет
      </template>
    </Card>
    <Toast position="bottom-right" />
  </div>
</template>
<script>

import store from "./state";
import Toast from "primevue/toast";
import SignIn from "@/components/Signin";
import Card from "primevue/card";
// import moment from "moment-timezone";



export default {
  components: {SignIn, Toast, Card},
  data() {
    return {
      question: '',
      answer: 'Questions usually contain a question mark. ;-)'
    }
  },
  computed: {
    error() {
      return this.$store.state.networkError
    },
    user(){
      return this.$store.state.user
    }
  },

  watch: {
    error: function (Error) {
      if (Error) this.$toast.add({
        severity: 'error',
        summary: 'Сервер не отвечает',
        detail: 'Отсуствует сетевое соединение или сервер недоступен',
        life: 4000
      });
    },
    user: function () {
      if (this.user){
        store.dispatch('getUserAreas',this.user.id);
      }
    },
  },



  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('The token in the local storage')

      store.commit('setToken', token);
      store.dispatch('getUser');
      // store.dispatch('getUserAreas', this.store.state.user.id);

    }
    store.dispatch('getPeriods');
    store.dispatch('getAreas');
    store.dispatch('getProjects');
    store.dispatch('getTracks');
  },

}
</script>
<style>
body {
  background-color: WhiteSmoke;
  /* background-color: var(--surface-b);*/
   font-family: var(--font-family);
   color: var(--text-color);

  }

</style>