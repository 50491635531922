<template>
  <!--    <div class="card col-8 col-offset-2">-->

  <Card>
    <template #title>
      {{ selectedProject.name }}
    </template>
    <template #content>
      <div class="field">
        <label for="period">Отчетный период</label>
        <Dropdown
          id="period"
          v-model="selectedPeriod"
          :options="periods"
          class="w-full"
          option-label="name"
          placeholder="Выберите отчетный период"
          @change="periodSelected()"
        />
      </div>

      <Accordion
        v-if="data_visible"
        :multiple="true"
      >
        <AccordionTab
          v-for="(item) in user_areas"
          :key="item.id"
          :header="item.name"
        >
          <div class="myscroll">
          <Panel
            v-for="item2 in metric_forms"
            :key="item2.id"
          >
            <template #header>
              <div v-if="!!item2.is_norma">
                <b>{{ item2.code }} {{ item2.name }}</b><br>(является нормирующим значением для других метрик)
              </div>
              <div v-else>
                <b>{{ item2.code }} {{ item2.name }}</b><br>
                <div v-if="!!!item2.is_binary">
                  <u>Нормирующий показатель:</u>
                  {{
                    metrics.find(e => e.id == item2.norma_metric_id) ? (metrics.find(e => e.id == item2.norma_metric_id)?.code + ' ' + metrics.find(e => e.id == item2.norma_metric_id)?.name) : 'НЕ ПРИМЕНЯЕТСЯ'
                  }}
                </div>
                <u>Вводимое значение:</u> {{ item2.input_description }}
              </div>
            </template>
            <!--             Если метрика не двоичная-->
            <div v-if="!!!item2.is_binary">
              <!--              Значение предыдущего периода-->

              Значение предыдущего периода {{ selectedPeriodPrevious?.name }}:
              <div
                v-if="metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                  && e.period_id == selectedPeriodPrevious?.id)?.value"
              >
                <b>{{
                  metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id && e.period_id == selectedPeriodPrevious.id)?.value
                }}</b>
                <div v-if="!!!metric_values.find(e => e.id == item2.id)?.is_norma && !!item2.norma_metric_id">
                  Нормирующее значение предыдущего периода {{ selectedPeriodPrevious?.name }}:
                  <b>{{
                    metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                      && e.period_id == selectedPeriodPrevious.id)?.norma_value ? metric_values.find(e =>
                      e.id == item2.id)?.metric_values.find(e => e.area_id == item.id && e.period_id ==
                      selectedPeriodPrevious.id)?.norma_value : 'ОТСУТСТВУЕТ'
                  }}</b><br>
                  Результирующее значение предыдущего периода {{ selectedPeriodPrevious?.name }}:
                  <b>{{
                    metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                      && e.period_id == selectedPeriodPrevious.id)?.norma_value > 0 ?
                      (metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                        && e.period_id == selectedPeriodPrevious.id)?.value / metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                        && e.period_id == selectedPeriodPrevious.id)?.norma_value * 100).toFixed(2) + '%' : 'РАСЧЕТ НЕВОЗМОЖЕН'
                  }} </b>
                </div>
              </div>

              <div v-else>
                <b>ОТСУТСТВУЕТ</b>
              </div>


              <!--              Значение выбранного периода-->
              Значение текущего периода:
              <div v-if="item2.metric_values.find(e => e.area_id == item.id)">
                <b>{{ item2.metric_values.find(e => e.area_id == item.id).value }}</b>
                <div v-if="!!!item2.is_norma && !!item2.norma_metric_id">
                  Нормирующее значение текущего периода:
                  <b>{{
                    item2.metric_values.find(e => e.area_id == item.id)?.norma_value ? item2.metric_values.find(e => e.area_id == item.id)?.norma_value : 'ОТСУТСТВУЕТ'
                  }}</b><br>
                  Результирующее значение текущего периода:
                  <b>{{
                    (item2.metric_values.find(e => e.area_id == item.id)?.norma_value > 0) ?
                      (item2.metric_values.find(e => e.area_id == item.id)?.value / item2.metric_values.find(e => e.area_id == item.id)?.norma_value * 100).toFixed(2) + '%' : 'РАСЧЕТ НЕВОЗМОЖЕН'
                  }} </b>
                </div>
              </div>
              <div v-else>
                <b>ОТСУТСТВУЕТ</b>
              </div>
            </div>

            <!--              Если метрика ДВОИЧНАЯ -->
            <div v-else>
              Значение предыдущего периода ({{ selectedPeriodPrevious?.name }}):
              <div
                v-if="metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                  && e.period_id == selectedPeriodPrevious?.id)?.value"
              >
                <b>
                  <div
                    v-if="!!metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                      && e.period_id == selectedPeriodPrevious?.id)?.value"
                  >Да</div>
                  <div v-else>Нет</div>
                </b>
              </div>
              <div v-else>
                <b>ОТСУТСТВУЕТ</b>
              </div>

              Значение текущего периода:
              <div v-if="!!!item2.metric_values.find(e => e.area_id == item.id)?.value">
                <b>ОТСУТСТВУЕТ</b>
              </div>
              <div v-else>
                <b>
                  <div v-if="!!parseInt(item2.metric_values.find(e => e.area_id == item.id)?.value)">Да</div>
                  <div v-else>Нет</div>
                </b>
              </div>
            </div>
            <!--  Информация о пользователе, который ввел знаечение и дате ввода  -->
            <!--  предыдущий период  -->
            <div
              v-if="!!metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                && e.period_id == selectedPeriodPrevious?.id)?.user_id"
            >
              Значение предыдущего периода введено пользователем: {{ metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                && e.period_id == selectedPeriodPrevious?.id)?.user_name }}.
              Дата ввода: {{ format_date_ru(metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id
                && e.period_id == selectedPeriodPrevious?.id)?.updated_at) }}
            </div>
            <!--  текущий период  -->
            <div v-if="!!item2.metric_values.find(e => e.area_id == item.id)?.user_id">
              Значение текущего периода введено пользователем: {{ item2.metric_values.find(e => e.area_id ==
                item.id)?.user_name }}.
              Дата ввода: {{ format_date_ru(item2.metric_values.find(e => e.area_id == item.id)?.updated_at) }}
            </div>
            <div v-if="!!!item2.is_norma">
              <p style="white-space: pre-wrap">
                <b>Комментарий для отчета:</b>
              {{ item2.metric_values.find(e => e.area_id == item.id)?.comment ?
                item2.metric_values.find(e => e.area_id == item.id).comment : "ОТСУТСТВУЕТ" }}
              </p>
            </div>
            <div class="mx-1 my-1">
              <Button
                :disabled="!is_admin && !is_manager && (item2.is_regional || Date.now() < start_at || Date.now() > end_at)"
                class="p-button-outlined"
                icon="pi pi-pencil"
                label="Редактировать"
                style="width: 200px"
                @click="editValue({
                  area_id: item.id,
                  area_name: item.name,
                  // period_id: selectedPeriod.id,
                  metric_id: item2.id,
                  metric_name: item2.name,
                  input_description: item2.input_description,
                  old_value: item2.metric_values.find(e => e.area_id == item.id) ?
                    item2.metric_values.find(e => e.area_id == item.id)?.value : null,
                  is_binary: item2.is_binary,
                  old_comment: item2.metric_values.find(e => e.area_id == item.id) ?
                    item2.metric_values.find(e => e.area_id == item.id)?.comment : null,
                  is_norma: item2.is_norma,
                  report_description: item2.report_description,
                  previous_value:
                    metric_values.find(e => e.id == item2.id)?.metric_values.find(e => e.area_id == item.id && e.period_id == selectedPeriodPrevious?.id)?.value,
                  norma: item2.metric_values.find(e => e.area_id == item.id)?.norma_value,
                  norma_previous:
                    metric_values.find(e => e.id == item2.id)?.metric_values.find(e =>
                      e.area_id == item.id && e.period_id == selectedPeriodPrevious?.id)?.norma_value

                })"
              />


              <div v-if="!is_admin && !is_manager && !!item2.is_regional">
                * Значение вводит региональный
                координатор
              </div>
              <div v-if="Date.now() < start_at">
                * Период ввода данных начнется
                {{ format_date_ru(selectedPeriod.start_at) }}
              </div>
              <div v-if="Date.now() > end_at">
                * Период ввода данных закончился
                {{ format_date_ru(selectedPeriod.end_at) }}
              </div>
            </div>
          </Panel>
          </div>
        </AccordionTab>
      </Accordion>
      <ProgressSpinner
          v-if="!data_visible && spinner_visible"
          stroke-width="1"
          class="flex justify-content-center"
          animation-duration="1s"
      />
    </template>
  </Card>

  <!--  </div>-->
  <Dialog
    v-model:visible="dialog_visible"
    :closable="true"
    :close-on-escape="true"
    :content-style="{height: '600px'}"
    :header="selectedMetricName"
    :maximizable="true"
    :modal="true"
    :style="{width: '60vw'}"
  >
    <!--    <div>-->
    <!--      <b>Проект: </b> {{ this.selectedProject.name }}-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <b>Период: </b> {{ this.selectedPeriod.name }}-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <b>Муниципальное образование: </b> {{ this.selectedAreaName }}-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <b>Метрика: </b> {{ this.selectedMetricName }}-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <b>Что необходимо ввести: </b>-->
    <!--      {{ this.selectedMetricInputDescription ? this.selectedMetricInputDescription : this.selectedMetricName }}-->
    <!--    </div>-->
    <div>
      <b>Значение предыдущего периода {{ selectedPeriodPrevious?.name }}: </b>
      <span v-if="!!!previousValue">
        <b>ОТСУТСТВУЕТ</b>
      </span>
      <span v-else>
        <b>
          <div v-if="selectedIsBinary">
            <div v-if="!!parseInt(previousValue)">Да</div>
            <div v-else>Нет</div>
          </div>
          <div v-else>

            {{ parseInt(previousValue) }}
            <span v-if="normaPrevious"> {{ !!!selectedIsNorma ? '(' + (parseInt(previousValue) /
              normaPrevious * 100).toFixed(2) + '%)' : '' }} </span>
          </div>
        </b>
      </span>
    </div>
    <div>
      <b>Текущее значение: </b>
      <span v-if="!!!oldValue">
        <b>ОТСУТСТВУЕТ</b>
      </span>
      <span v-else>
        <b>
          <div v-if="selectedIsBinary">
            <div v-if="!!parseInt(oldValue)">Да</div>
            <div v-else>Нет</div>
          </div>
          <div v-else>
            {{ parseInt(oldValue) }}
            <span v-if="norma"> {{ !!!selectedIsNorma ? '(' + (parseInt(oldValue) / norma *
              100).toFixed(2) + '%)' : '' }} </span>
          </div>
        </b>
      </span>
      <!--      <div v-if="!this.selectedIsNorma">-->
      <!--      <b>Текущий комментарий для отчета МО: </b>-->
      <!--      {{this.oldComment ? this.oldComment : "ОТСУТСТВУЕТ"}}-->
      <!--      </div>-->
    </div>
    <div v-if="!selectedIsBinary">
      <div class="field">
        <label for="newValue"><b>Введите</b>
          {{ selectedMetricInputDescription ? selectedMetricInputDescription : selectedMetricName }}
          <div v-if="v$.newValue.$error">
            <span
              v-for="(error, index) of v$.newValue.$errors"
              id="newValue-error"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small></span>
          </div>
        </label>
        <InputNumber
          id="newValue"
          v-model="newValue"
          :class="{ 'p-invalid': v$.newValue.$error }"
          locale="ru-RU"
          placeholder="Введите значение метрики"
        />
        <!--        @change="this.valueEdited()"-->
      </div>
    </div>


    <div v-else>
      <label for="value">Введите
        {{
          selectedMetricInputDescription ? selectedMetricInputDescription : selectedMetricName
        }}</label><br>
      <Dropdown
        id="value"
        v-model="newValue"
        :options="binary_values"
        option-label="name"
        placeholder="Выберите значение метрики"
        @change="valueEdited()"
      />
    </div>

    <div v-if="!selectedIsNorma">
      <div class="field">
        <label
          for="newComment"
          style="white-space: pre-line"
        ><b>Введите комментарий, анализирующий динамику изменения
           метрики относительно предыдущего периода.</b><br> {{ reportDescription }}<br>

          <div v-if="v$.newComment.$error">
            <span
              v-for="(error, index) of v$.newComment.$errors"
              id="newValue-error"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small></span>
          </div>
        </label><br>

        <Textarea
          id="newComment"
          v-model="newComment"
          rows="5"
          cols="120"
          :class="{ 'p-invalid': v$.newComment.$error }"
          locale="ru-RU"
          placeholder="Введите комментарий, анализирующий динамику изменения метрики относительно предыдущего периода. "
        />
      </div>
    </div>

    <template #footer>
      <Button
        :disabled="submit_disabled"
        icon="pi pi-check"
        icon-pos="left"
        label="Сохранить"
        @click="store_value()"
      />
      <Button
        icon="pi pi-check"
        label="Закрыть"
        @click="closeDialog"
      />
    </template>
  </Dialog>
  <Toast position="bottom-right" />
</template>

<script>
import store from "@/state";
import Dropdown from "primevue/dropdown";
// import Button from "primevue/button";
// import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Card from "primevue/card";
import Panel from "primevue/panel";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import {isEmpty} from "lodash/lang";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import moment from "moment-timezone";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Textarea from "primevue/textarea";
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "EditMetricValues",
  components: {Dropdown, Toast, Panel, Dialog, Button, InputNumber, Card, Accordion, AccordionTab, Textarea, ProgressSpinner},
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      // period_disabled: true,
      data_visible: false,
      spinner_visible: false,
      // selectedProject: '',
      selectedPeriod: '',
      selectedAreaName: '',
      selectedMetricName: '',
      selectedMetricInputDescription: '',
      selectedIsBinary: '',
      oldValue: '',
      newValue: '',
      oldComment: '',
      newComment: '',
      selectedIsNorma: '',
      reportDescription: '',
      previousValue: '',
      norma: '',
      normaPrevious: '',
      // metric_forms: [],
      dialog_visible: false,
      submit_disabled: false,
      binary_values: [
        {name: 'Да', value: 1},
        {name: 'Нет', value: 0},
      ],
    }
  },
  computed: {

    is_admin() {
      return store.state.user.is_admin;
    },

    is_manager() {
      return store.state.user.is_manager;
    },

    projects() {
      return store.state.projects;
    },
    selectedProject:
        function () {
          return this.$store.state.projects.find(e => e.id == this.$route.params.id);
        },
    periods() {
      console.log('periods COMPUTED')
      return store.state.periods.filter(e => e.project_id == this.selectedProject.id);
    },
    selectedPeriodPrevious() {
      return store.state.periods.find(e => e.project_id == this.selectedProject.id && e.id ==
          this.selectedPeriod.prev_period_id);
    },
    user_areas() {
      return store.state.userAreas;
    },
    metrics() {

      return store.state.metrics.filter(e => e.periods.map(x => {
        return x.id
      }).includes(this.selectedPeriod.id));

    },
    metric_values() {
      return store.state.metricValues;
    },

    start_at() {
      // return new Date(new Date(Date.parse(this.selectedPeriod.start_at)).toLocaleString('en',
      //     {timeZone: 'Etc/UTC'}));
      return new Date(Date.parse(this.selectedPeriod.start_at + ' GMT-0000'));
    },

    end_at() {
      // return new Date(new Date(Date.parse(this.selectedPeriod.end_at)).toLocaleString('en',
      //     {timeZone: 'Etc/UTC'}));
      return new Date(Date.parse(this.selectedPeriod.end_at + ' GMT-0000'));
    },

    metric_forms() {
      const result = this.metrics.map(e => {
        return {
          ...e,
          metric_values: this.metric_values.filter(x => x.id == e.id)[0].metric_values.filter(y => y.period_id == this.selectedPeriod.id),
        }
      })
      console.log('--------------metric_forms:------------------ ');
      console.log(result);
      return result;
    }


  },

  validations() {
    if (!this.selectedIsNorma)
    return {
      newValue: {required: helpers.withMessage('Поле явялется обязательным', required),},
      newComment: {required: helpers.withMessage('Поле явялется обязательным', required),},
    }
    else
      return {
        newValue: {required: helpers.withMessage('Поле явялется обязательным', required),},
      }
  },

  watch: {

    newComment: function () {

      console.log(this.newComment);
    },
  },

  methods: {
    // projectSelected() {
    //   this.period_disabled = false;
    //   this.data_visible = false;
    // },

    format_date_ru(datetime) {
      moment.tz.setDefault("UTC");
      return moment(datetime, '', 'ru').tz("Asia/Yekaterinburg").format('LLL');
    },


    periodSelected() {
      this.spinner_visible = true;
      store.dispatch('getMetrics', this.selectedProject.id)
          .then(() => {
                store.dispatch('getMetricValues', this.selectedProject.id)
                    .then(() => {
                      this.data_visible = true;
                      this.spinner_visible = false;
                      console.log('--------------metrics:------------------ ');
                      console.log(JSON.stringify(this.metrics, null, 2));
                      console.log('--------------metric_values:------------------ ');
                      console.log(JSON.stringify(this.metric_values, null, 2));
                    })

              }
          )
    },
    editValue({ area_id, area_name, metric_id, metric_name, input_description, old_value, is_binary,
              old_comment, is_norma, report_description, previous_value, norma, norma_previous }) {
      this.dialog_visible = true;
      this.selectedAreaId = area_id;
      this.selectedAreaName = area_name;
      this.selectedMetricId = metric_id;
      this.selectedMetricName = metric_name;
      this.oldValue = old_value;
      this.newValue = old_value;
      this.selectedMetricInputDescription = input_description;
      this.selectedIsBinary = is_binary;
      this.selectedIsNorma = is_norma;
      this.oldComment = old_comment;
      this.newComment = old_comment;
      this.reportDescription = report_description;
      this.previousValue = previous_value;
      this.norma = norma;
      this.normaPrevious = norma_previous;
      this.v$.$reset();
      // this.v$.newComment.reset();
      console.log('NORMA: ', this.norma);
      console.log('NORMA_PREVIOUN: ', this.norma_previous);
    },
    closeDialog() {
      this.dialog_visible = false;
    },
    valueEdited() {
      // if (this.newValue > 0)
      this.submit_disabled = false;

    },

    async store_value() {
      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) return
      const bodyParameters = {
        "area_id": this.selectedAreaId,
        "metric_id": this.selectedMetricId,
        "period_id": this.selectedPeriod.id,
        "value": this.newValue.value == null ? this.newValue : this.newValue.value,
        "comment": this.newComment,
      };
      store.dispatch('storeMetricValue', bodyParameters).then(data => {
        console.log('DATA^ ', data)
        if (!isEmpty(data.code))
          this.$toast.add({severity: 'error', summary: 'Ошибка добавления данных', detail: data.message, life: 4000});
        else if (!isEmpty(data.data))
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка добавления данных',
            detail: {...data.data.errors, error: data.data.message},
            life: 4000
          });
        else {
          this.$toast.add({severity: 'success', summary: 'Данные добавлены', detail: data, life: 4000});
          store.dispatch('getMetricValues', this.selectedProject.id);
          this.closeDialog();


        }

      })
    }
  },
}
</script>

<style scoped>
.myscroll {
  height: 75vh;
  display: block;
  overflow: scroll;
}

</style>
