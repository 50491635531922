<template>
  <div class="card">
    <Toolbar class="mb-4">
      <template #end>
        <Button
          class="p-button-success mr-2"
          icon="pi pi-plus"
          label="Добавить пользователя"
          @click="viewUserDialog()"
        />
      </template>
    </Toolbar>

    <DataTable
      :value="users"
      responsive-layout="scroll"
    >
      <Column
        field="lastname"
        header="Фамилия"
      />
      <Column
        field="firstname"
        header="Имя"
      />
      <Column
        field="surname"
        header="Отчество"
      />
      <Column
        field="company"
        header="Организация"
      />
      <Column
        field="position"
        header="Должность"
      />
      <Column
        field="email"
        header="Логин"
      />
      <Column
        field="phone"
        header="Телефон мобильный"
      />
      <Column
        field="phone_work"
        header="Телефон рабочий"
      />
      <Column header="Админ">
        <template #body="{data}">
          {{ data.is_admin == 1 ? 'Да' : 'Нет' }}
        </template>
      </Column>
      <Column header="Рег. координатор">
        <template #body="{data}">
          {{ data.is_manager == 1 ? 'Да' : 'Нет' }}
        </template>
      </Column>
      <Column
        field="areas_list"
        header="Уполномочен"
      />
      <Column
        style="min-width:8rem"
        header="Действия"
      >
        <template #body="{data}">
          <Button
            class="p-button-rounded p-button-success mr-2"
            icon="pi pi-pencil"
            @click="editUser(data)"
          />
          <Button
            class="p-button-rounded p-button-danger"
            icon="pi pi-trash"
            @click="deleteUser($event,data )"
          />
        </template>
      </Column>
    </DataTable>
  </div>
  <User
    :dialog-visible="userDialogVisible"
    :user="current_user"
    :user_action="user_action"
  />
  <ConfirmDialog
    id="confirm"
    aria-label="popup"
  />
</template>

<script>

import store from "@/state";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import User from "@/components/User";
import ConfirmDialog from 'primevue/confirmdialog';
import {isEmpty} from "lodash/lang";

export default {
  name: "UsersTable",
  components: {DataTable, Column, Toolbar, Button, User, ConfirmDialog},

  data() {
    return {
      userDialogVisible: false,
      user_action: '',
      current_user: {},
      // confirm: useConfirm(),

    }
  },
  computed: {

    users:
        function () {
          return this.$store.state.users.map(e => {
            return {
              ...e, areas_list: e.areas.map(a => {
                return a.name
              }).join(', ')
            }
          })
        },
  },
  mounted() {
    store.dispatch('getUsers');
  },
  methods: {
    editUser(user){
      this.userDialogVisible = !this.userDialogVisible;
      this.user_action = 'edit';
      this.current_user = user;
      console.log('current_user=');
      console.log(JSON.stringify(this.current_user, null, 2));
    },
    viewUserDialog(name, id) {
      this.userDialogVisible = !this.userDialogVisible;
      this.user_action = 'create';
      this.userName = name;
      this.currentUserID = id;
      console.log(this.userDialogVisible)
    },
    deleteUser(event, user) {
      this.$confirm.require({
        header: 'Данное действие необратимо!',
        message: 'Вы действительно ходите удалить пользователя ' + user.lastname + ' ' + user.firstname + ' ' + user.surname + '?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'удалить',
        rejectLabel: 'Отмена',
        acceptIcon: 'pi pi-exclamation-circle',
        rejectIcon: 'pi pi-times',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-info',
        accept: () => {
          store.dispatch('deleteUser', user.id).then(data => {
            console.log('DATA^ ', data)
            if (!isEmpty(data.code))
              this.$toast.add({
                severity: 'error',
                summary: 'Ошибка добавления пользователя',
                detail: data.message,
                life: 4000
              });
            else if (!isEmpty(data.data))
              this.$toast.add({
                severity: 'error',
                summary: 'Ошибка добавления пользователя',
                detail: {...data.data.errors, error: data.data.message},
                life: 4000
              });
            else {
              this.$toast.add({severity: 'success', summary: 'Пользователь успешно удален', detail: data, life: 4000});
              store.dispatch('getUsers');
              }

          })

        },
        reject: () => {
          //callback to execute when user rejects the action
        },
        onShow: () => {
          //console.log('Del user show')
        },
        onHide: () => {
          //callback to execute when popup is hidden
        },

      });
    },

  }
}
</script>

<style scoped>

</style>