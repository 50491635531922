// import { createApp } from 'vue'
// import App from './App.vue'
//
// createApp(App).mount('#app')

import { createApp } from 'vue'
import axios from 'axios';
import router from './router.js'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ToastService from 'primevue/toastservice';
import '@vuelidate/core'
import '@vuelidate/validators'
import ConfirmationService from 'primevue/confirmationservice';
import BadgeDirective from 'primevue/badgedirective';



import store from './state.js';


const myApp = createApp(App);
console.log(myApp.config)
window.axios = axios;
myApp.use(store)
myApp.use(router)
myApp.use(ToastService);
myApp.use(PrimeVue, {
    locale: {
        closeText: 'Закрыть',
        prevText: 'Назад',
        nextText: 'Вперёд',
        currentText: 'Home',
        monthNames: ['Январь', 'февраль' , 'Март' , 'Апрель' , 'Май' , 'Июнь' , 'Июль' , 'Август' , 'Сентябрь','Октябрь','Ноябрь','Декабрь' ],
        monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек' ],
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['Воск','Пон' , 'Вт' , 'Ср' , 'Четв' , 'Пят' , 'Суб'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        weekHeader: 'Неделя',
        FirstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix:'',
        timeOnlyTitle: 'Только время',
        timeText: 'Время',
        hourText: 'Час',
        minuteText: 'Минута',
        secondText: 'Секунда',
        ampm: false,
        month: 'Месяц',
        week: 'неделя',
        day: 'День',
        allDayText: 'Весь день'
    }
})
myApp.use(ConfirmationService);
myApp.mount('#app');
myApp.directive('badge', BadgeDirective);


