<template>
  <Dialog
      v-model:visible="DV"
      :closable="true"
      :close-on-escape="false"
      :maximizable="true"
      :modal="true"
      :style="{width: '50vw'}"
      @hide="closeDialog()"
  >
    <template #header>
      <h3>Комментарии по группам показателей для отчета - {{ period.name }}</h3>
    </template>
    <div>
      <div class="card">
        <Chart type="bar" :data="this.chartData"  :options="chartOptions" class="h-[30rem]" />
<!--        <div-->
<!--            v-for="(item, key) in metricGroupsCommentsStatistic"-->
<!--            :key = "key"-->
<!--            style="margin: 10px"-->
<!--        >-->
<!--          {{areas.find( e => e.id == key)?.name}}-->

<!--          <ProgressBar :value="item['approved'] / item['total'] * 100"> {{ item['approved'] }} / {{ item['total'] }}</ProgressBar>-->
<!--        </div>-->
      </div>
    </div>

    <!--    <template #footer>-->
    <!--      <Button icon="pi pi-check" label="Закрыть" @click="closeDialog"/>-->
    <!--    </template>-->
  </Dialog>
</template>

<script>

// import Button from "primevue/button";
import Dialog from "primevue/dialog";
// import ProgressBar from 'primevue/progressbar';


import store from "@/state";
import Chart from "primevue/chart";


export default {
  name: "MetricGroupsCommentsStatistic",
  components: {Dialog, Chart},
  props: ['project', 'period', 'dialogVisible'],

  data() {
    return {
      DV: false,
      parameterDialogClosable: true,
      // selectedAreas: null,
      // selectedMetric: '',

      chartOptions: {
        indexAxis: 'y',
        maintainAspectRatio: true,
        aspectRatio: 0.8,

        scales: {
          x: {
            stacked: true,
            ticks: {
              // color: textColorSecondary,
              font: {
                weight: 500
              }
            },
            grid: {
              display: true,
              drawBorder: true
            }
          },
          y: {
            stacked: true,
            ticks: {
              // color: textColorSecondary
            },
            grid: {
              // color: surfaceBorder,
              drawBorder: true
            }
          }
        }
      }

    }
  },
  computed: {
    metricGroupsCommentsStatistic() {
      return store.state.metricGroupsCommentsStatistic;
    },



    periods() {
      console.log('periods COMPUTED')
      return store.state.periods;
    },
    areas() {
      console.log('areas COMPUTED')
      return store.state.areas;
    },
    chartData() {
      return {

        labels: this.metricGroupsCommentsStatistic.map(e => this.areas.find(x => x.id == e.area_id).name),
        datasets: [
          {
            type: 'bar',
            label: 'Одобрено',
            backgroundColor: 'green',
            data: this.metricGroupsCommentsStatistic.map(e => e.approved),
          },
          {
            type: 'bar',
            label: 'Отклонено',
            backgroundColor: 'red',
            data: this.metricGroupsCommentsStatistic.map(e => e.refused),
          },
          {
            type: 'bar',
            label: 'На проверке',
            backgroundColor: 'blue',
            data: this.metricGroupsCommentsStatistic.map(e => e.on_moderation),
          },
          {
            type: 'bar',
            label: 'Отсуствует',
            backgroundColor: 'grey',
            data: store.state.metricGroupsCommentsStatistic.map(e => e.total - e.on_moderation - e.approved - e.refused),
          }
        ]
      }
    }
  },
  watch: {

    dialogVisible: function () {
      // this.documentStyle = getComputedStyle(document.documentElement);

      console.log('this.period.id', this.period.id)
      store.dispatch('getMeteicGroupsCommentsStatistic', [this.period.id])
          .then(() => {
                console.log("getMeteicGroupsCommentsStatistic")
              }
          )
      this.DV = true;
    },
  },

  methods: {

    closeDialog() {

      this.DV = false;
    },



  },
};

</script>

<style scoped>
.p-progressbar{
  background: red
}


</style>
