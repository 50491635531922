<template>
  <div>
    <Panel>
      <template #header>
        <h2> {{ project.name }}</h2>
      </template>

      <div class="card flex flex-wrap gap-3 p-fluid align-items-end ">
        <div
          class="field"
          style="width: 30%"
        >
          <label for="period">Отчетный период</label>
          <Dropdown
            id="period"
            v-model="selectedPeriod"
            :options="periods"
            class="w-full"
            option-label="name"
            placeholder="Выберите отчетный период"
            @change="periodSelected()"
          />
        </div>


        <div
          class="field"
          style="width: 30%"
        >
          <label for="metric">Показатель проекта</label>
          <Dropdown
            id="metric"
            v-model="selectedMetric"
            :options="metric_values.map((e) => { return {...e, sname: e.code + ' ' + e.name.slice(0,999)}}).filter(e => !!!e.is_norma)"
            option-label="sname"
            placeholder="Выберите метрику проекта"
            style="width: 100%"
            :disabled="metricSelectDisabled"
          />
        </div>

        <div class="field">
          <Button
            class="p-button-raised p-button-info"
            icon="pi pi-pencil"
            icon-pos="left"
            label="Построить карту"
            @click="draw()"
          />
        </div>
      </div>
    </Panel>
    <div class="card">
      <div class="card-container flex align-items-start justify-content-start">
        <div class="flex-1 p-4 border-round align-items-center">
          <Chart
            :data="chartData"
            :options="chartOptions"
            class="h-30rem"
            type="line"
          />
        </div>

        <div
          class="p-4 border-round align-items-start"
          style="max-width: 400px;"
        >
          <Tag
            v-for="area of successAreas"
            :key="area"
            class="m-1"
            severity="success"
            :value="area"
          /> <br>
          <Tag
            v-for="area of failedAreas"
            :key="area"
            class="m-1"
            severity="danger"
            :value="area"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/state";
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
// import MultiSelect from "primevue/multiselect";
import Chart from "primevue/chart";
import Button from "primevue/button";
import Tag from "primevue/tag";


// import _ from "lodash";


export default {
  name: "ShukhartMap",
  components: {Panel, Dropdown, Chart, Button, Tag},
  data() {
    return {
      selectedMetric: null,
      selectedPeriod: null,
      metricSelectDisabled: true,
      successAreas: [],
      failedAreas: [],
      // selectedAreas: [],
      values: [],
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.1,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#495057'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#495057'
            }
          }
        }
      },
    }

  },
  computed:
      {
        selectedProjectId() {
          return this.$route.params.id
        },
        project:
            function () {
              return this.$store.state.projects.find(e => e.id == this.$route.params.id);
            },
        areas() {
          console.log('areas COMPUTED')
          return store.state.areas;
        },

        periods() {

          return store.state.periods.filter(e => e.project_id == this.selectedProjectId)

        },

        metric_values() {
          console.log('----------metric_values:---------- ');
          console.log(JSON.stringify(store.state.metricValues, null, 2));
          return store.state.metricValues;
        },
        actual_metric_values() {
          return this.metric_values.filter(e => e.track_id == this.selectedTrack);
        },

      },


  methods: {
    periodSelected() {
      store.dispatch('getMetricValues', this.$route.params.id)
          .then(() => {
                this.metricSelectDisabled = false;
              }
          )
    },

    draw() {
      this.chartData = {
        labels: [],
        datasets: []
      };

      if (this.selectedPeriod && this.selectedMetric) {

        this.chartData.labels = this.areas.map(e => e.name);


        this.chartData.datasets.push([]); // Значения
        this.chartData.datasets.push([]); // Медиана
        this.chartData.datasets.push([]); // Верхняя граница
        this.chartData.datasets.push([]); // Нижняя граница

        this.chartData.datasets[0].fill = false;
        this.chartData.datasets[1].fill = false;
        this.chartData.datasets[2].fill = false;
        this.chartData.datasets[3].fill = false;

        this.chartData.datasets[0].tension = 0;
        this.chartData.datasets[1].tension = 0;
        this.chartData.datasets[2].tension = 0;
        this.chartData.datasets[3].tension = 0;

        this.chartData.datasets[0].label = this.metric_values.find(e => e.id == this.selectedMetric.id)?.name.slice(0,500);
        this.chartData.datasets[1].label = "Медиана";
        this.chartData.datasets[2].label = "Верхняя граница";
        this.chartData.datasets[3].label = "Нижняя граница";

        this.chartData.datasets[0].borderColor = "Blue";
        this.chartData.datasets[1].borderColor = "Red";
        this.chartData.datasets[2].borderColor = "LimeGreen";
        this.chartData.datasets[3].borderColor = "LimeGreen";
        let values = [];
        if (this.selectedMetric.not_normalized)
        {
          values = this.areas.map(x => this.metric_values.find(e => e.id == this.selectedMetric.id)?.metric_values.filter(e => (e.period_id == this.selectedPeriod.id && e.area_id == x.id)).map(e => e.value)[0])
        }
        else
        {
          values = this.areas.map(x => this.metric_values.find(e => e.id == this.selectedMetric.id)?.metric_values.filter(e => (e.period_id == this.selectedPeriod.id && e.area_id == x.id)).map(e => e.value / e.norma_value * 100)[0])
        }

        console.log('----------values:---------- ');
        console.log(JSON.stringify(values, null, 2));

        this.chartData.datasets[0].data = values.slice(0)
        let med = this.median(values.slice(0).filter(e => e != null))
        console.log('Медиана: ', med);
        this.chartData.datasets[1].data = values.map(() => med)
        // среднее отклонения от медианы
        let R = this.average(values.map(e => Math.abs(e - med)).filter(x => !!x))

        console.log('среднее отклонения от медианы: ', R);
        this.chartData.datasets[2].data = values.map(() => med + 3 * R / 3.819)
        this.chartData.datasets[3].data = values.map(() => med - 3 * R / 3.819)

        this.successAreas = this.areas.filter((e, i) => values[i] >= this.chartData.datasets[2].data[0]).map(e => e.name)
        this.failedAreas = this.areas.filter((e, i) => values[i] <= this.chartData.datasets[3].data[0]).map(e => e.name)


        console.log('DATASET ');
        console.log(this.chartData.datasets[0].data);
        console.log(this.chartData.datasets[1].data);
      }


    },


    median(array) {
      console.log('--------Массив для расчета медианы---------- ');
      console.log(JSON.stringify(array, null, 2));
      if (array.length == 0) return undefined;
      array.sort((a, b) => a - b);
      console.log('--------Сортированный Массив для расчета медианы---------- ');
      console.log(JSON.stringify(array, null, 2));
      let midpoint = Math.floor(array.length / 2); // 2.
      console.log('midpoint: ', midpoint);
      let median = array.length % 2 === 1 ? array[midpoint] :  (parseFloat(array[midpoint - 1]) +
          parseFloat(array[midpoint])) / 2;
      return median
    },
    average(array) {
      console.log('--------Массив для расчета среднего---------- ');
      console.log(JSON.stringify(array, null, 2));
      if (array.length == 0) return undefined;
      return array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / array.length;
    },
  },

}


</script>

<style>


</style>
