<template>
  <div>
    <Panel>
      <template #header>
        <h2> {{ project.name }}</h2>
      </template>


      <div class="field">
        <label for="track">Трек (набор метрик)</label>
        <Dropdown
          id="track"
          v-model="selectedTrack"
          :options="tracks"
          class="w-full"
          option-label="name"
          option-value="id"
          placeholder="Выберите трек"
        />
      </div>

      <div class="field">
        <label for="area">Города (районы) для анализа</label>
        <MultiSelect
          id="area"
          v-model="selectedAreas"
          :options="areas"
          option-label="name"
          panel-class="col-2"
          placeholder="Выберите город (район)"
        />
      </div>

      <div class="flex m-2">
        <Button
          class="p-button-raised p-button-info"
          icon="pi pi-users"
          icon-pos="left"
          label="Построить диаграмму"
          @click="draw()"
        />
      </div>
    </Panel>
    <div class="card flex justify-content-center">
      <Chart
        :data="chartData"
        :options="chartOptions"
        class="w-full md:w-30rem"
        type="radar"
      />
    </div>
  </div>
</template>

<script>
import store from "@/state";
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Chart from "primevue/chart";
import Button from "primevue/button";

// import _ from "lodash";


export default {
  name: "TracksAnalysis",
  components: {Panel, Dropdown, MultiSelect, Chart, Button},
  data() {
    return {
      selectedTrack: null,
      selectedAreas: [],
      values: [],
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: '#495057',
            }
          }
        }
      }
    }

  },

  computed:
      {
        project:
            function () {
              return this.$store.state.projects.find(e => e.id == this.$route.params.id);
            },
        areas() {
          console.log('areas COMPUTED')
          return store.state.areas;
        },
        // metrics() {
        //   return store.state.metrics;
        // },

        tracks() {
          console.log('---tracks---------------');
          console.log(JSON.stringify(store.state.tracks, null, 2));
          return store.state.tracks.filter(e => e.project_id == this.$route.params.id);
        },
        periods() {
          // Актуальные периоды (в которых имеются данные метрик с track_id текущего проекта)
          console.log('periods COMPUTED')
          console.log('---periods---------------');
          // console.log(JSON.stringify(this.metric_values.filter(x => this.tracks.map(y => y.id).includes(x.track_id)).map(t => t.metric_values.map(p => p.period_id))), null, 2);

          console.log(JSON.stringify(store.state.periods.filter(e => this.metric_values.filter(x => this.tracks.map(y => y.id).includes(x.track_id)).map(t => t.metric_values.map(p => p.period_id)).flat().includes(e.id)), null, 2));
          return store.state.periods.filter(e => this.metric_values.filter(x => this.tracks.map(y => y.id).includes(x.track_id)).map(t => t.metric_values.map(p => p.period_id)).flat().includes(e.id));
        },

        metric_values() {
          return store.state.metricValues;
        },

        actual_metrics() {
          return store.state.metrics.filter(e => e.tracks.map(x => {
            return x.id
          }).includes(this.selectedTrack));

        },
        actual_metric_values() {
          return this.metric_values.filter(e => this.actual_metrics.map(x => {return x.id }).includes(e.id));
          // return this.metric_values.filter(e => e.track_id == this.selectedTrack);
        },

      },

  mounted() {
    console.log('Проект=', this.$route.params.id);
    store.dispatch('getMetrics', this.$route.params.id);
  },


  methods: {
    draw() {
      this.chartData = {
        labels: [],
        datasets: []
      };
      if (this.selectedAreas && this.selectedTrack) {
        store.dispatch('getMetricValues', this.$route.params.id)
            .then(() => {
                  console.log('---perioda------------');
                  console.log(JSON.stringify(this.periods), null, 2);
                  console.log('---metric_values------------');
                  console.log(JSON.stringify(this.metric_values), null, 2);
                  // Оси диаграммы
                  this.chartData.labels = this.actual_metric_values.map(e => 'Метрика ' + e.code);
                  // Генерация цветов
                  let i;
                  let j;
                  let colors = [];
                  for (i = 0; i < this.periods.length; ++i) {
                    colors.push(Math.round(i * 360 / this.periods.length).toString());
                  }
                  colors.sort(() => Math.round(Math.random() * 100) - 50)

                  for (i = 0; i < this.periods.length; ++i) {
                    this.chartData.datasets.push({});
                    this.chartData.datasets[i].label = this.periods[i].name;
                    this.chartData.datasets[i].data = [];
                    // this.chartData.datasets[i].data = [Math.random() * 100, Math.random() * 100, Math.random() * 100, Math.random() * 100, Math.random() * 100,]
                    this.chartData.datasets[i].borderColor = 'hsl(' + colors.pop() + ', 60%, 50%)';
                    for (j = 0; j < this.actual_metric_values.length; ++j) {
                      // console.log('Все актуальные метрики ');
                      // console.log(JSON.stringify(this.actual_metric_values[j]), null, 2);
                      console.log('текущая метрика =========== ');
                      console.log(JSON.stringify(this.actual_metric_values[j]), null, 2);
                      console.log('ХНАЧЕНИЯ текущая метрика =========== ');
                      console.log(JSON.stringify(this.actual_metric_values[j].metric_values), null, 2);
                      console.log('ХНАЧЕНИЯ текущая метрика для периода ', this.periods[i].name);
                      console.log(JSON.stringify(this.actual_metric_values[j].metric_values.filter(e => e.period_id == this.periods[i].id)), null, 2);
                      console.log('Значения для AREA ', this.selectedAreas);
                      console.log(JSON.stringify(
                              this.actual_metric_values[j].metric_values.filter(e => e.period_id == this.periods[i].id).filter(e => this.selectedAreas.map(x => x.id).includes(e.area_id)).map(x => x.value / x.norma_value)
                          )
                          , null, 2);
                      console.log('MEDIANA для AREAS ', this.selectedAreas);
                      console.log(this.median(
                              this.actual_metric_values[j].metric_values.filter(e => e.period_id == this.periods[i].id).filter(e => this.selectedAreas.map(x => x.id).includes(e.area_id)).map(x => x.value / x.norma_value)
                          )
                      );
                      let median_value_for_selected_areas_and_selected_period = 0;
                      if (this.actual_metric_values[j].is_binary)
                      {
                        median_value_for_selected_areas_and_selected_period = this.average(
                            this.actual_metric_values[j].metric_values.filter(e => e.period_id == this.periods[i].id).filter(e => this.selectedAreas.map(x => x.id).includes(e.area_id)).map(x => x.value)
                        );
                      }
                      else
                        median_value_for_selected_areas_and_selected_period = this.median(
                            this.actual_metric_values[j].metric_values.filter(e => e.period_id == this.periods[i].id).filter(e => this.selectedAreas.map(x => x.id).includes(e.area_id)).map(x => x.value / x.norma_value)
                        );
                      this.chartData.datasets[i].data.push(median_value_for_selected_areas_and_selected_period);
                    }
                  }
                }
            );

      }
    },


    median(array) {
      console.log('--------Массив для расчета медианы---------- ');
      console.log(JSON.stringify(array, null, 2));
      if (array.length == 0) return undefined;
      array.sort((a, b) => a - b);
      let midpoint = Math.floor(array.length / 2); // 2.
      let median = array.length % 2 === 1 ? array[midpoint] : (array[midpoint - 1] + array[midpoint]) / 2;
      return median
    },
    average(array) {
      console.log('--------Массив для расчета среднего---------- ');
      console.log(JSON.stringify(array, null, 2));
      if (array.length == 0) return undefined;
      return array.reduce((a, b) => parseInt(a) + parseInt(b), 0) / array.length;
    },

  },

}


</script>

<style>


</style>
