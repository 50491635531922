<template>
  <Dialog
    v-model:visible="DV"
    :closable="true"
    :close-on-escape="false"
    :maximizable="true"
    :modal="true"
    :style="{width: '50vw'}"
    @hide="closeDialog()"
  >
    <template #header>
      <h3>{{ project.name }}</h3>
    </template>
    <div class="field">
      <label for="metric">Показатель проекта</label>
      <Dropdown
        id="metric"
        v-model="selectedMetric"
        :options="metric_values.map((e) => { return {...e, sname: e.code + ' ' + e.name.slice(0,99)}}).filter(e => !!!e.is_norma)"
        option-label="sname"
        placeholder="Выберите метрику проекта"
      />
    </div>
    <div class="field">
      <label for="area">Город (район)</label>
      <MultiSelect
        id="area"
        v-model="selectedAreas"
        :options="areas"
        panel-class="col-2"
        option-label="name"
        placeholder="Выберите город (район)"
      />
    </div>
    <div>
      <div class="card">
        <Chart
          :data="basicData"
          :options="basicOptions"
          type="bar"
        />
      </div>
    </div>

    <!--    <template #footer>-->
    <!--      <Button icon="pi pi-check" label="Закрыть" @click="closeDialog"/>-->
    <!--    </template>-->
  </Dialog>
</template>

<script>

// import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Chart from "primevue/chart";
import Dropdown from "primevue/dropdown";
import MultiSelect from 'primevue/multiselect';
import store from "@/state";


export default {
  name: "ProjectChart",
  components: {Dialog, Chart, Dropdown, MultiSelect},
  props: ['dialogVisible', 'project'],

  data() {
    return {
      DV: false,

      parameterDialogClosable: true,
      selectedAreas: null,
      selectedMetric: '',
      // metrics: [],
      basicData: {
        labels: [],
        datasets: []
      },
      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },

    }
  },
  computed: {
    metric_values() {
      return store.state.metricValues;
    },

    periods() {
      console.log('periods COMPUTED')
      return store.state.periods;
    },
    areas() {
      console.log('areas COMPUTED')
      return store.state.areas;
    },
  },
  watch: {

    project: function () {
      // console.log('PROJECT WATCHED');
      // this.metrics = this.project.metrics;
      // console.log('THI ID OF THE PROJECT=',this.project.id);
      // store.dispatch('getProjectWithMetricValues', this.project.id);
      store.dispatch('getMetricValues', this.project.id)
          .then(()=>{
            this.data_visible = true;
            // console.log('--------------metrics:------------------ ');
            // console.log(JSON.stringify(this.metrics, null, 2));
            console.log('--------------metric_values:------------------ ');
            console.log(JSON.stringify(this.metric_values, null, 2));
          })

    },


    selectedMetric: function (){
      console.log('Выбрана метрикИ: ', this.selectedMetric);
      this.draw();
    },

    selectedAreas: function (){
      // console.log(this.selectedAreas);
      this.draw();
    },

    dialogVisible: function () {
      this.DV = true;
      console.log('watched');
    },
  },

  methods: {

    closeDialog() {
      this.selectedAreas = null;
      this.selectedMetric = null;
      this.basicData = {
            labels: [],
            datasets: []
      };
      this.DV = false;
    },

    draw() {
      this.basicData = {
        labels: [],
        datasets: []
      };
      if (this.selectedAreas && this.selectedMetric) {
        let actual_periods = [];
        actual_periods = this.periods.filter(e => this.metric_values.find(x => x.id == this.selectedMetric.id)?.metric_values.map(y => y.period_id).includes(e.id)).map(e => e.id);
        console.log('actual_periods: ');
        console.log(actual_periods);
        this.basicData.labels = this.periods.filter(e => actual_periods.includes(e.id)).map(e => e.name);
        let i;
        let j;
        // Генерация цветов
        let colors = [];
        for (i = 0; i < this.selectedAreas.length; ++i) {
          colors.push(Math.round(i * 360 / this.selectedAreas.length).toString());
        }
        colors.sort(() => Math.round(Math.random() * 100) - 50)
        for (i = 0; i < this.selectedAreas.length; ++i) {
          this.basicData.datasets.push([]);
          this.basicData.datasets[i].label = this.selectedAreas[i].name;
          this.basicData.datasets[i].backgroundColor = 'hsl(' + colors.pop() +', 60%, 50%)';
          this.basicData.datasets[i].data = []
          for (j = 0; j < actual_periods.length; ++j) {
            //если метрика двоичная или не нормализованная
            if (this.selectedMetric.is_binary || this.selectedMetric.not_normalized)
            {
              this.basicData.datasets[i].data.push(this.metric_values.find(e => e.id == this.selectedMetric.id)?.metric_values.find(e => (e.area_id == this.selectedAreas[i].id) && (e.period_id == actual_periods[j]))?.value);
            }
            else {
              this.basicData.datasets[i].data.push(
                  this.metric_values.find(e => e.id == this.selectedMetric.id)?.metric_values.find(e => (e.area_id == this.selectedAreas[i].id) && (e.period_id == actual_periods[j]))?.value
                  / this.metric_values.find(e => e.id == this.selectedMetric.id)?.metric_values.find(e => (e.area_id == this.selectedAreas[i].id) && (e.period_id == actual_periods[j]))?.norma_value * 100
              );
            }
          }
          console.log('DATASET ', i);
          console.log(this.basicData.datasets[i]);
        }

      }
    }
  },
};

</script>

<style scoped>

</style>
