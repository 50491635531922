<template>
  <Dialog
    v-model:visible="DV"
    :closable="true"
    :close-on-escape="true"
    :content-style="{height: '300px'}"
    :header="'Метрики проекта '+title+':'"
    :maximizable="true"
    :modal="true"
    :style="{width: '50vw'}"
  >
    <DataTable
      :scrollable="true"
      :value="metrics.metrics"
      scroll-height="flex"
    >
      <Column
        field="code"
        header="Код"
        style="max-width:50px"
      />
      <Column
        field="name"
        header="Описание"
        style="min-width:300px"
      />
    </DataTable>
    <template #footer>
      <Button
        icon="pi pi-check"
        label="Закрыть"
        @click="closeDialog"
      />
    </template>
  </Dialog>
</template>

<script>

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "MetricsList",
  components: {Dialog, DataTable, Button, Column},
  props: ['title', 'dialogVisible', 'metrics'],

  data() {
    return {
      DV: false,

    }
  },
  watch: {

    dialogVisible: function () {
      this.DV = true;
      console.log('watched');
    },
  },
  methods: {

    closeDialog() {
      this.DV = false;
    },
  },
};

</script>

<style scoped>

</style>