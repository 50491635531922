<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="text-3xl font-medium text-900 surface-300 mb-3">
      1. Регистрация и вход в систему
    </div>
    <div class="mb-3">
      Регистрация пользователей региональным координатором, имеющим полномочия администратора (далее –
      Администратор). Для входа в систему региональный координатор передает пользователю логин и пароль для входа.
      Для входа в систему необходимо ввести логин и пароль в форму входа:
    </div>
    <div class="text-center mb-6">
      <img src="../assets/img001.png">
    </div>
    <div class="text-3xl font-medium text-900 surface-300 mb-3 mt-6">
      2. Редактирование профиля пользователя
    </div>
    <div class="mb-3">
      Для редактирования профиля пользователю необходимо после входа в систему в выпадающем меню
      выбрать <b>Профиль</b>:
    </div>
    <div class="text-center mb-6">
      <img src="../assets/img002.png">
    </div>
    <div class="mb-3">
      Для того, чтобы внести изменения в профиль, нужно нажать кнопку <b>Редактировать информацию</b>:
    </div>
    <div class="text-center mb-6">
      <img src="../assets/img003.png">
    </div>
    <div class="mb-3">
      В появившемся окне можно внести изменения в профиль и сохранить изменения:
    </div>
    <div class="text-center mb-6">
      <img src="../assets/img004.png">
    </div>
    <div class="text-3xl font-medium text-900 surface-300 mb-3 mt-6">
      3. Ввод данных
    </div>
    <div class="mb-3">
      Для ввода данных необходимо выбрать проект и нажать кнопку <b>Ввод данных</b>:
    </div>
    <div class="text-center mb-6">
      <img src="../assets/img005.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "DocUser"
}
</script>

<style scoped>

</style>