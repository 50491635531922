<template>
  <Toolbar class="mb-4" v-if="is_admin || is_manager">
    <template #end>
      <Button
        class="p-button-success mr-2"
        icon="pi pi-plus"
        label="Добавить период"
        @click="createPeriod()"
      />
    </template>
  </Toolbar>
  <Card v-if="selectedProject">
    <template #title>
      {{ selectedProject.name }}
    </template>
    <template #content>
      <DataTable
        :value="periods"
        responsive-layout="scroll"
      >
        <Column
          field="id"
          header="№"
        />
        <Column
          field="name"
          header="Наименование периода"
        />
        <Column
          field="start_at"
          header="Начало ввода данных"
        >
          <template #body="{data}">
            {{ format_date_ru(data.start_at) }}
          </template>
        </Column>
        <Column
          field="end_at"
          header="Окончание ввода данных"
        >
          <template #body="{data}">
            {{ format_date_ru(data.end_at) }}
          </template>
        </Column>
        <Column
          field="start_at"
          header="Начало подачи отчетов"
        >
          <template #body="{data}">
            {{ format_date_ru(data.report_start_at) }}
          </template>
        </Column>
        <Column
          field="end_at"
          header="Окончание подачи отчетов"
        >
          <template #body="{data}">
            {{ format_date_ru(data.report_end_at) }}
          </template>
        </Column>

        <Column
          field="end_at"
          header="Статистика периода"
        >
          <template #body="{data}">
            Всего метрик: {{ metrics_count(data.id) }}<br>
            Нормирующих метрик: {{ norma_count(data.id) }}<br>
            <span @click="viewStatisticDialog(data)" class="mylink">Статистика данных</span><br>
            <span @click="viewMetricGroupsCommentsStatisticDialog(data)" class="mylink">Статистика отчетов</span>

          </template>
        </Column>

        <Column
          v-if="is_admin || is_manager"
          header="Действия"
          bodyStyle="text-align:center"
        >
          <template #body="{data}">
            <p>
            <Button
                disabled=true
              class="p-button-rounded p-button-danger mr-2"
              icon="pi pi-microchip-ai"
              label="AI-модерация"
              @click="moderateMetricGroupComments(data.id)"
            />
            </p>
            <p>
            <Button
                class="p-button-rounded p-button-secondary mr-2"
                icon="pi pi-wrench"
                label="AI-модерация test"
                @click="testModerateMetricGroupComments(data.id)"
            />
            </p>
            <p>
            <Button
                class="p-button-rounded p-button-success mr-2"
                icon="pi pi-pencil"
                label="Редактировать"
                @click="editPeriod(data)"
            />
            </p>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Period
    :dialog-visible="periodDialogVisible"
    :period="current_period"
    :action="action"
    :project="this.selectedProject"
  />
  <ConfirmDialog
    id="confirm"
    aria-label="popup"
  />
  <ProjectStatistic
      :project="this.selectedProject"
      :period="current_period"
      :dialogVisible="statisticDialogVisible"
  />
  <MetricGroupsCommentsStatistic
      :project="this.selectedProject"
      :period="current_period"
      :dialogVisible="metricGroutstatisticDialogVisible"
  />
</template>

<script>

import store from "@/state";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import ConfirmDialog from 'primevue/confirmdialog';
// import {isEmpty} from "lodash/lang";
import Card from "primevue/card";
import Period from "@/components/Period";
import moment from 'moment-timezone';
import ProjectStatistic from "@/components/ProjectStatistic.vue";
import MetricGroupsCommentsStatistic from "@/components/MetricGroupsCommentsStatistic.vue";
import {isEmpty} from "lodash/lang";



export default {
  name: "ProjectPeriods",
  components: {
    MetricGroupsCommentsStatistic,
    DataTable, Column, Toolbar, Button, ConfirmDialog, Card, Period, ProjectStatistic},
  props: ['id'],


  data() {
    return {
      periodDialogVisible: false,
      statisticDialogVisible: false,
      metricGroutstatisticDialogVisible: false,
      action: 'edit',
      current_period: {},


    }
  },

  computed: {
    selectedProject() {
          return this.$store.state.projects.find(e => e.id == this.$route.params.id);
        },
    periods() {
         return store.state.periods.filter(e => e.project_id == this.selectedProject?.id)
    },
    is_admin(){
      if (store.state.user != null) { return store.state.user.is_admin } else return false;
    },

    is_manager(){
      if (store.state.user != null) { return store.state.user.is_manager } else return false;
    },

    metrics(){
      return store.state.metrics.filter(e => e.project_id == this.selectedProject.id)
    },
    projects(){
      return store.state.projects;
    },
    areas() {
      console.log('areas COMPUTED')
      return store.state.areas;
    },



  },

  mounted() {
    console.log('Periods component MOUNTED!');
    store.dispatch('getMetrics', this.$route.params.id);
    console.log('Current Project=', this.selectedProject.id);
  },

  methods: {
    viewStatisticDialog(period) {
      this.current_period = period;
      console.log('current_period=');
      this.statisticDialogVisible = !this.statisticDialogVisible;
    },
    viewMetricGroupsCommentsStatisticDialog(period) {
      this.current_period = period;
      console.log('current_period=');
      this.metricGroutstatisticDialogVisible = !this.metricGroutstatisticDialogVisible;
    },

    moderateMetricGroupComments(period_id) {
      console.log('current_period=');
      store.dispatch('moderateMetricGroupComments', period_id)
          .then(() => {
                console.log('Запрос на модерацию комментариев отправлен');
              }
          )
    },



    testModerateMetricGroupComments(period_id) {
      console.log('current_period=');
      store.dispatch('testModerateMetricGroupComments', period_id)
          .then((data) => {
                console.log('DATA^ ', data)
                if (!isEmpty(data.code))
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка запроса на тестовую модерацию',
                    detail: data.message,
                    life: 4000
                  });
                else if (!isEmpty(data.data))
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка запроса на тестовую модерацию',
                    detail: {...data.data.errors, error: data.data.message},
                    life: 4000
                  });
                else {
                  this.$toast.add({severity: 'success', summary: 'Запрос на тестовую модерацию поставлен в очередь', detail: data, life: 4000});
                }
                }
          )
    },

    format_date_ru(datetime){
      moment.tz.setDefault("UTC");
      return moment(datetime,'','ru').tz("Asia/Yekaterinburg").format('LLL');
    },

    editPeriod(period){
      this.current_period = period;
      this.periodDialogVisible = !this.periodDialogVisible;
      this.action = 'edit';
      console.log('current_period=');
      console.log(JSON.stringify(this.current_period, null, 2));
    },

    createPeriod(){
      this.periodDialogVisible = !this.periodDialogVisible;
      this.action = 'create';
      console.log('Creating New Period');
    },

    metrics_count(period_id){
      return this.metrics.filter(e => e.periods.map(x => {
        return x.id
      }).includes(period_id)).length;
    },
    norma_count(period_id){
      return store.state.metrics.filter(e => e.periods.map(x => {return x.id}).includes(period_id) && !!e.is_norma).length;
    },
  }
}
</script>

<style scoped>
.special-day {
  text-decoration: line-through
}
  .mylink {
  cursor: pointer;
    color: mediumblue;


}
</style>