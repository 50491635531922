<template>
  <div>
    <Panel>
      <template #header>
        <h2> {{ project_name }}</h2>
      </template>
      {{ project_description }}
    </Panel>
    <Panel class="w-full">
      <div class="field">
        <label for="area">Анализ динамики</label>
        <Dropdown
          id="area"
          v-model="selected_dynamics_method"
          :options="dynamics_methods"
          class="w-full"
          option-label="name"
          option-value="value"
          :placeholder="selected_dynamics_method.name"
          @change="dynamicsMethodSelected()"
        />
      </div>

      <div class="field">
        <label for="period">Отчетный период</label>
        <Dropdown
          id="period"
          v-model="selectedPeriod"
          :options="periods"
          class="w-full"
          option-label="name"
          placeholder="Выберите отчетный период"
          @change="periodSelected()"
        />
      </div>
    </Panel>

    <DataTable
      :value="values"
      scroll-height="400px"
      show-gridlines
    >
      <Column
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
      >
        <template #body="slotProps">
          <!--          <div :class = "col.field.indexOf('median') == 0 && col.field.indexOf('median_') == -1 ? 'bolded' : col.field.indexOf('value') == 0 ? parseFloat(slotProps.data[col.field]) == parseFloat(slotProps.data['median']) ? 'soso' : parseFloat(slotProps.data[col.field]) > parseFloat(slotProps.data['median']) ? 'good' : 'bad' : ''">-->
          <!--            {{slotProps.data['is_binary'] && col.field.indexOf('value') == 0 ?-->
          <!--              slotProps.data[col.field] == 1 ? "Да" : "Нет" :-->
          <!--              col.field.indexOf('value') == 0 || col.field.indexOf('median') == 0 ? (parseFloat(slotProps.data[col.field]) * 100).toFixed(2) + " %" : slotProps.data[col.field]}}-->
          <!--          </div>-->

          <div :class="col.field.indexOf('median') == 0 && col.field.indexOf('median_') == -1 ? 'bolded' : col.field.indexOf('value') == 0 ? slotProps.data['marker'+col.field.slice(5)] : ''">
            {{
              slotProps.data[col.field] === undefined ? "Нет данных" :
              slotProps.data['is_binary'] && col.field.indexOf('value') == 0 ?
                slotProps.data[col.field] == 1 ? "Да" : "Нет" :

                (col.field.indexOf('value') == 0 || col.field.indexOf('median') == 0) ?
                  !slotProps.data['not_normalized'] ?
                    (parseFloat(slotProps.data[col.field]) * 100).toFixed(2) + " %" : parseFloat(slotProps.data[col.field]).toFixed(2)
                  : slotProps.data[col.field] }}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import store from "@/state";
import Column from "primevue/column";
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";

// import _ from "lodash";


export default {
  name: "ProjectResults",
  components: {DataTable, Column, Panel, Dropdown},
  props: ['id'],
  data() {
    return {
      selectedPeriod: '',
      values: [],
      selected_dynamics_method: 1,
      dynamics_methods: [
        {name: 'Относительно медианного значения', value: 1},
        {name: 'Относительно предыдущего периода', value: 0},
      ]
      }

  },
  computed:
      {
        selectedProjectId(){
          return this.$route.params.id
        },
        areas() {
          console.log('areas COMPUTED')
          return store.state.areas;
        },
        metrics() {
          return store.state.metrics.filter(e => e.periods.map(x => {
            return x.id
          }).includes(this.selectedPeriod.id));

        },
        // periods() {
        //   console.log('periods COMPUTED')
        //   return store.state.periods.filter(e => e.project_id == this.selectedProject);
        // },
        periods() {
          if (this.selected_dynamics_method == 1)
            return store.state.periods.filter(e => e.project_id == this.selectedProjectId)
          else
            return store.state.periods.filter(e => (e.project_id == this.selectedProjectId && e.prev_period_id))
        },
        metric_values(){
          return store.state.metricValues;
        },
        project_name:
            function () {
              return this.$store.state.projects.find(e => e.id == this.selectedProjectId)?.name
            },
        project_description:
            function () {
              return this.$store.state.projects.find(e => e.id == this.selectedProjectId)?.description
            },
        columns:
        function () {
          let m = this.areas.map(x => ({header: x.name, field: "value"+x.id}));
          if (this.selected_dynamics_method == 1) {
            m.unshift({header: "Медиана по районам", field: "median_district"});
            m.unshift({header: "Медиана по городам", field: "median_city"});
            m.unshift({header: "Медиана по региону", field: "median"});
          }
          m.unshift({header: "Метрика", field: "metric"});
          m.unshift({header: "Код", field: "code"});
          return m
        },
      },

  watch: {
    selectedProjectId:
        {}
  },
  methods: {
    dynamicsMethodSelected(){
      store.dispatch('getPeriods');
    },

    median(array){
      console.log('--------Массив для расчета медианы---------- ');
      console.log(JSON.stringify(array, null, 2));
      if (array.length == 0) return undefined;
      array.sort((a, b) => a - b);
      let midpoint = Math.floor(array.length / 2); // 2.
      let median = array.length % 2 === 1 ? array[midpoint] : (array[midpoint - 1] + array[midpoint]) / 2;
      return median
    },
    average (array){
      console.log('--------Массив для расчета среднего---------- ');
      console.log(JSON.stringify(array, null, 2));
      if (array.length == 0) return undefined;
      return array.reduce((a, b) => parseInt(a) + parseInt(b), 0) / array.length;
    },

    periodSelected() {
      store.dispatch('getMetrics', this.selectedProjectId)
          .then(() => {
            console.log('--------------metrics:------------------ ');
            console.log(JSON.stringify(this.metrics, null, 2));
                store.dispatch('getMetricValues', this.selectedProjectId)
                    .then(() => {
                      // this.data_visible = true;

                      console.log('--------------metric_values:------------------ ');
                      console.log(JSON.stringify(this.metric_values, null, 2));
                      this.values = [];
                      this.metrics.filter(e => e.is_norma == 0).forEach((metric) =>
                          {
                            console.log('metric=', metric.id);
                            let row = {};
                            row['is_binary'] = metric.is_binary;
                            row['not_normalized'] = metric.not_normalized;
                            row['code'] = metric.code;
                            row['metric'] = metric.name;
                            // массив metric_values для текущей метрики и периода
                            let curr_metric_values =
                                this.metric_values.find(e => e.id == metric.id)?.metric_values.filter(e =>
                                    e.period_id == this.selectedPeriod.id && (e.norma_value > 0 || metric.is_binary == 1
                                        || metric.not_normalized == 1));
                            // массив metric_values для текущей метрики и ПРЕДЫДУЩЕГО периода
                            let prev_metric_values = []
                            if (this.selected_dynamics_method == 0) {
                              prev_metric_values =
                                  this.metric_values.find(e => e.id == metric.id)?.metric_values.filter(e =>
                                      e.period_id == this.selectedPeriod.prev_period_id && (e.norma_value > 0 ||
                                          metric.is_binary == 1 || metric.not_normalized == 1));
                            }
                            // Расчет медиан для метода динамики относительно медианы
                            if (this.selected_dynamics_method == 1) {
                              // массив значений для расчета медианы по региону. Если метрика бинарная, то расчитывается среднее значение в диапазоне 0-1
                              let values_array = (metric.is_binary || metric.not_normalized) ?
                                  curr_metric_values.map(e => e.value) :
                                  curr_metric_values.map(e => e.value / e.norma_value);
                              row['median'] = metric.is_binary || metric.not_normalized ? this.average(values_array) : this.median(values_array);
                              console.log("Медиана:",  row['median'])
                              // то же для медианаы по городам
                              values_array = (metric.is_binary || metric.not_normalized) ? curr_metric_values.filter(e => e.area.area_type == 'city').map(e => e.value) :
                                  curr_metric_values.filter(e => e.area.area_type == 'city').map(e => e.value / e.norma_value);
                              row['median_city'] = metric.is_binary || metric.not_normalized ? this.average(values_array) : this.median(values_array);

                              // то же для медианы по районам
                              values_array = (metric.is_binary || metric.not_normalized) ? curr_metric_values.filter(e => e.area.area_type == 'district').map(e => e.value) :
                                  curr_metric_values.filter(e => e.area.area_type == 'district').map(e => e.value / e.norma_value);
                              row['median_district'] = metric.is_binary || metric.not_normalized ? this.average(values_array) : this.median(values_array);
                            }
                            this.areas.forEach((area) =>
                            {

                              if (!(curr_metric_values.find(e => e.area_id == area.id)?.value === undefined))
                              {
                                //Расчет самих значений
                                row["value" + area.id] = (metric.is_binary || metric.not_normalized) ? curr_metric_values.find(e => e.area_id == area.id)?.value :
                                    curr_metric_values.find(e => e.area_id == area.id)?.value / curr_metric_values.find(e => e.area_id == area.id)?.norma_value;

                                // Расчет маркеров для метода динамики относительно медианы
                                if (this.selected_dynamics_method == 1) {
                                  row["marker" + area.id] =
                                      metric.is_binary == 1 ?
                                          curr_metric_values.find(e => e.area_id == area.id)?.value == 1 ? "good" : "bad"
                                          : metric.not_normalized == 1 ? curr_metric_values.find(e => e.area_id == area.id)?.value  >= row['median'] ? "good" : "bad"
                                          : curr_metric_values.find(e => e.area_id == area.id)?.value /
                                              curr_metric_values.find(e => e.area_id == area.id)?.norma_value >= row['median'] ? "good" : "bad"

                                }
                                // Расчет маркеров для метода динамики относительно ПРЕДЫДУЩЕГО периода
                                if (this.selected_dynamics_method == 0) {
                                  let curr_value = 0;
                                  let prev_value = 0;
                                  if (metric.is_binary == 0) {
                                    if (metric.not_normalized == 0) {
                                      curr_value = curr_metric_values.find(e => e.area_id == area.id)?.value / curr_metric_values.find(e => e.area_id == area.id)?.norma_value;
                                      prev_value = prev_metric_values.find(e => e.area_id == area.id)?.value / prev_metric_values.find(e => e.area_id == area.id)?.norma_value;
                                    } else {
                                      curr_value = curr_metric_values.find(e => e.area_id == area.id)?.value;
                                      prev_value = prev_metric_values.find(e => e.area_id == area.id)?.value;
                                    }
                                    if ((curr_value - prev_value) / prev_value > 0.05) {
                                      row["marker" + area.id] = "good";
                                    }

                                    if ((curr_value - prev_value) / prev_value < -0.05) {
                                      row["marker" + area.id] = "bad";
                                    }
                                  }
                                  else {
                                    curr_value = curr_metric_values.find(e => e.area_id == area.id)?.value;
                                    prev_value = prev_metric_values.find(e => e.area_id == area.id)?.value;
                                    if ((curr_value - prev_value)  >= 0) {
                                      row["marker" + area.id] = "good";
                                    }
                                    else {
                                      row["marker" + area.id] = "bad";
                                    }
                                  }
                                }
                              }
                              // else row["value" + area.id] = 'Нет данных';
                            })
                            this.values.push(row)

                          }
                      )


                      console.log('--------------values:------------------ ');
                      console.log(JSON.stringify(this.values, null, 2));
                    })

              }
          )

    },
  }
}


</script>

<style>

.layout{
  margin: 10px;
}
.bad{
  font-weight: 700;
  color: #FF5252;
}

.soso{
  font-weight: 700;
  color: #FFA726;
}

.good{
  font-weight: 700;
  color: #66BB6A;
}

.bolded{
  font-weight: 700;
}

</style>
