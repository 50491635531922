import { createRouter, createWebHistory } from 'vue-router'
// import Homepage from './components/Homepage'
import projects from "@/components/Projects";
import ProjectReport from "@/components/ProjectReport";
import EditMetricValues from "@/components/EditMetricValues";
import users from "@/components/Users";
import SupportForm from "@/components/SupportForm";
import metrics from "@/components/Metrics";
import UserProfile from "@/components/UserProfile";
import ProjectResults from "@/components/ProjectResults";
import periods from "@/components/Periods";
import Tracks from "@/components/Tracks";
import ShukhartMap from "@/components/ShukhartMap";
import DocUser from "@/components/DocUser";
import Report from "@/components/Report";

const routes = [
    {
        path: '/',
        component: projects,
    },
    {
        path: '/projects',
        component: projects,
    },
    {
        path: '/metrics/:id',
        component: metrics,
    },
    {
        path: '/periods/:id',
        component: periods,
        // props: true
    },
    {
        path: '/users',
        component: users,
    },
    {
        path: '/support',
        component: SupportForm,
    },
    {
        path: '/projectreport/:id',
        component: ProjectReport,

    },
    {
        path: '/editmetricvalues/:id',
        component: EditMetricValues,
        props: true
    },
    {
        path: '/userprofile',
        component: UserProfile,
        props: true
    },
    {
        path: '/projectresults/:id',
        component: ProjectResults,
        props: true
    },
    {
        path: '/tracks/:id',
        component: Tracks,
        props: true
    },
    {
        path: '/shukhart/:id',
        component: ShukhartMap,
        props: true
    },
    {
        path: '/report/:id',
        component: Report,
        props: true
    },
    {
        path: '/docuser',
        component: DocUser,
        props: true
    },


]
const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router;
