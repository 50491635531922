<template>
  <Dialog
    v-model:visible="DV"
    :closable="true"
    :close-on-escape="false"
    :maximizable="true"
    :modal="true"
    :style="{width: '50vw'}"
    @hide="closeDialog()"
  >
    <template #header>
      <h3>{{ period.name }}</h3>
    </template>
    <div>
      <div class="card">
        <div
            v-for="(item, key) in periodStatistic"
            :key = "key"
            style="margin: 10px"
        >
            {{key == "regional" ? "Региональный координатор" : areas.find( e => e.id == key)?.name}}

            <ProgressBar :value="item['filled'] / item['all'] * 100"> {{ item['filled'] }} / {{ item['all'] }}</ProgressBar>
        </div>
      </div>
    </div>

    <!--    <template #footer>-->
    <!--      <Button icon="pi pi-check" label="Закрыть" @click="closeDialog"/>-->
    <!--    </template>-->
  </Dialog>
</template>

<script>

// import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressBar from 'primevue/progressbar';

import store from "@/state";


export default {
  name: "ProjectStatistic",
  components: {Dialog, ProgressBar},
  props: ['project', 'period', 'dialogVisible'],

  data() {
    return {
      DV: false,

      parameterDialogClosable: true,
      selectedAreas: null,
      selectedMetric: '',


    }
  },
  computed: {
    periodStatistic() {
      return store.state.periodStatistic;
    },

    periods() {
      console.log('periods COMPUTED')
      return store.state.periods;
    },
    areas() {
      console.log('areas COMPUTED')
      return store.state.areas;
    },
  },
  watch: {

    dialogVisible: function () {
      console.log('this.period.id', this.period.id)
      store.dispatch('getPeriodStatistic', [this.period.id])
          .then(() => {
                console.log("getPeriodStatistic")
              }
          )
      this.DV = true;
    },
  },

  methods: {

    closeDialog() {

      this.DV = false;
    },



  },
};

</script>

<style scoped>
  .p-progressbar{
    background: red
  }


</style>
