import {createStore} from "vuex";
import router from "@/router";
// import {error} from "@babel/eslint-parser/lib/convert";

const backendUrl = process.env.VUE_APP_BACKEND_URL;
const client_id = process.env.VUE_APP_CLIENT_ID;
const client_secret = process.env.VUE_APP_CLIENT_SECRET;


const store = createStore({
    state: {
        user: {},
        // projectsWithMetricValues: [],
        periods: [],
        areas: [],
        userAreas: [],
        projects: [],
        users: [],
        metrics: [],
        metricGroups: [],
        metricGroupComments: [],
        reportComments: [],
        token: null,
        preLoading: false,
        wrongPassword: false,
        networkError: false,
        loggedIn: false,
        metricValues: [],
        tracks: [],
        backurl: backendUrl,
        periodStatistic: [],
        metricGroupsCommentsStatistic: [],
    },

    mutations: {


        setToken(context, token) {
            context.token = token;
        },

        getToken(context, token) {
            context.token = token;
        },

        setUser(context, user) {
            context.user = user;
        },

        setPreloading(context, is_load) {
            context.preLoading = is_load;
        },
        setWrongPassword(context, isError) {
            context.wrongPassword = isError
        },

        setNetworkError(context, isError) {
            context.networkError = isError
        },
        setLoggedIn(context, state) {
            context.loggedIn = state
        },
        getNetworkError(context) {
            return context.networkError
        },
        // setProjectsWithMetricValues(context, data){
        //     context.projectsWithMetricValues = data
        //     console.log('projectsWithMetricValues IN THE STORE');
        // },
        setPeriods(context, data) {
            context.periods = data
            console.log('periods IN THE STORE');
        },
        setAreas(context, data) {
            context.areas = data
            console.log('areas IN THE STORE');
        },
        setUserAreas(context, data) {
            context.userAreas = data
            console.log('userAreas IN THE STORE');
        },
        setMetricValues(context, data) {
            context.metricValues = data
            console.log('metricValues IN THE STORE');
        },
        setProjects(context, data) {
            context.projects = data
            console.log('projects IN THE STORE');
        },
        setUsers(context, data) {
            context.users = data
            console.log('users IN THE STORE');
        },
        setMetrics(context, data) {
            context.metrics = data
            console.log('metrics IN THE STORE');
        },
        setMetricGroupComments(context, data) {
            context.metricGroupComments = data
            console.log('metricGroupComments IN THE STORE');
        },
        setReportComments(context, data) {
            context.reportComments = data
            console.log('reportComments IN THE STORE');
            console.log('reportComments = ', context.reportComments);
        },
        setMetricGroups(context, data) {
            context.metricGroups = data
            console.log('metricGroups IN THE STORE');
        },
        setTracks(context, data) {
            context.tracks = data
            console.log('tracks IN THE STORE');
        },
        setPeriodStatistic(context, data) {
            context.periodStatistic = data
            console.log('periodStatistic IN THE STORE');
        },
        setMetricGroupsCommentsStatistic(context, data) {
            context.metricGroupsCommentsStatistic = data
            console.log('metricGroupsCommentsStatistic IN THE STORE');
        },


        // logout(context) {
        //     context.user = null;
        //     context.token = null;
        //     context.loggedIn = false;
        //     localStorage.removeItem('token')
        // },
    },
    actions: {
        auth(context, {login, password}) {
            context.commit('setNetworkError', false)
            context.commit('setWrongPassword', false)
            window.axios.post(backendUrl + '/login', {
                email: login,
                password: password,
                grant_type: 'password'
            }, {
                headers: {
                    Authorization: 'Basic ' + window.btoa(client_id + ':' + client_secret)
                }
            }).then(response => {
                context.commit('setToken', response.data.token);
                context.commit('setLoggedIn', true);
                localStorage.setItem('token', response.data.token);
                context.dispatch('getUser').then()
            })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        // context.commit('setWrongPassword', false)
                        context.commit('setWrongPassword', true)
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        context.commit('setNetworkError', true)

                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        },

        logout(context) {
            context.commit('setNetworkError', false)
            window.axios.get(backendUrl + '/logout',
                {
                    headers: {
                        Authorization: 'Bearer ' + context.state.token
                    }
                }).then(() => {
                    context.commit('setToken', null);
                    context.commit('setLoggedIn', false);
                    context.commit('setUser', null);
                    localStorage.removeItem('token');
                    router.push('/');
                }
            )
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        // context.commit('setWrongPassword', false)
                        context.commit('setWrongPassword', true)
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        context.commit('setNetworkError', true)

                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        },
        getUser(context) {
            // context.commit('setPreloading', true);
            context.commit('setNetworkError', false)
            return window.axios
                .get(backendUrl + '/user', {
                    headers: {
                        Authorization: 'Bearer ' + context.state.token
                    }
                })
                .then((response) => {

                    context.commit('setUser', response.data);
                    context.commit('setLoggedIn', true);
                    // context.commit('setPreloading', false);

                })
                .catch((error) => {
                    console.log(error.toString())
                    context.commit('setLoggedIn', false);
                })
        },


        getRequest(context, url) {
            console.log('getRequest DISPATCHED');
            context.commit('setNetworkError', false)
            const config = {
                headers: {
                    "Authorization": "Bearer " + store.state.token,
                    "Content-Type": "multipart/form-data"
                }
            };
            return window.axios.get(backendUrl + url, config
            ).then(response => {
                console.log(response.data)
                return response.data
            })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        // context.commit('setWrongPassword', false)
                        store.commit('setNetworkError', true)
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        store.commit('setNetworkError', true)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        },
        //@todo
        getFile(context, url) {
            console.log('getRequest DISPATCHED');
            context.commit('setNetworkError', false)
            const config = {
                responseType: 'arraybuffer',
                headers: {
                    "Authorization": "Bearer " + store.state.token,
                    "Content-Type": "multipart/form-data"
                }
            };
            return window.axios.get(backendUrl + url, config
            ).then(response => {
                console.log(response.data)
                return response.data
            })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        // context.commit('setWrongPassword', false)
                        store.commit('setNetworkError', true)
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        store.commit('setNetworkError', true)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        },

        postRequest(context, params) {
            console.log('POST request DISPATCHED...');
            context.commit('setNetworkError', false)
            const url = params[0];
            const parameters = params[1];
            const config = {
                headers: {
                    "Authorization": "Bearer " + store.state.token,
                    "Content-Type": "multipart/form-data"
                }
            };
            console.log('PARAMETERS:', parameters);
            console.log('URL:', url);
            return window.axios.post(backendUrl + url, parameters, config).then(response => {
                console.log('RESPONSE DATA: ', response.data)
                return response.data
            })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        // context.commit('setWrongPassword', false)
                        // store.commit('setNetworkError', true)
                        console.log('ERROR RESPONSE: ', error.response)
                        return error.response
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log('ERROR REQUEST', error.request);
                        store.commit('setNetworkError', true)
                        return error
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('ERROR MESSAGE', error.message);
                        return error.message
                    }
                    // console.log(error.config);
                })
        },

        deleteRequest(context, params) {
            console.log('DELETE request DISPATCHED...');
            context.commit('setNetworkError', false)
            const url = params[0];
            const parameters = params[1];
            const config = {
                headers: {
                    "Authorization": "Bearer " + store.state.token,
                    "Content-Type": "multipart/form-data"
                }
            };
            console.log('PARAMETERS:', parameters);
            console.log('URL:', url);
            return window.axios.delete(backendUrl + url, config).then(response => {
                console.log('RESPONSE DATA: ', response.data)
                return response.data
            })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        // context.commit('setWrongPassword', false)
                        // store.commit('setNetworkError', true)
                        console.log('ERROR RESPONSE: ', error.response)
                        return error.response
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log('ERROR REQUEST', error.request);
                        store.commit('setNetworkError', true)
                        return error
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('ERROR MESSAGE', error.message);
                        return error.message
                    }
                    // console.log(error.config);
                })
        },

        getPeriods(context) {
            context.dispatch('getRequest', "/periods/").then(data => {
                store.commit('setPeriods', data);
                console.log('getPeriods DISPATCHED');
            })
        },
        getAreas(context) {
            context.dispatch('getRequest', "/areas/").then(data => {
                store.commit('setAreas', data);
                console.log('getAreas DISPATCHED');
            })
        },
        getUserAreas(context, user_id) {
            context.dispatch('getRequest', '/users/' + user_id + '/areas').then(data => {
                store.commit('setUserAreas', data);
                console.log('getUserAreas DISPATCHED');
            })
        },
        getMetricValues(context, project_id) {
            return context.dispatch('getRequest', '/metricvalues/' + project_id).then(data => {
                store.commit('setMetricValues', data);
                console.log('getMetricValues DISPATCHED для проекта', project_id);
            })
        },
        getProjects(context) {
            context.dispatch('getRequest', '/projectswith/metrics/').then(data => {
                store.commit('setProjects', data);
                console.log('getProjects DISPATCHED');
            })
        },
        getUsers(context) {
            context.dispatch('getRequest', '/users/').then(data => {
                store.commit('setUsers', data);
                console.log('getUsers DISPATCHED');
            })
        },
        storeMetricValue(context, parameters) {
            return context.dispatch('postRequest', ['/metricvalues/', parameters]).then(data => {
                console.log('storeMetricValue DISPATCHED',);
                console.log('RESPONSE:', data);
                return data;
            })
        },
        storeUser(context, parameters) {
            return context.dispatch('postRequest', ['/user/', parameters]).then(data => {
                console.log('parameters',);
                console.log('storeUser DISPATCHED',);
                console.log('RESPONSE:', data);
                return data;
            })
        },
        updateUser(context, parameters) {
            return context.dispatch('postRequest', ['/user/' + parameters[0], parameters[1]]).then(data => {
                console.log('parameters', parameters);
                console.log('storeUser DISPATCHED',);
                console.log('RESPONSE:', data);
                return data;
            })
        },
        storeUserAreas(context, parameters) {
            console.log('parameters',);
            console.log('storeUserAreas DISPATCHED',);
            return context.dispatch('postRequest', ['/user/' + parameters[0] + '/addareas/', parameters[1]]).then(data => {

                console.log('RESPONSE:', data);
                return data;
            })
        },
        storePeriodMetrics(context, parameters) {
            console.log('parameters',);
            console.log('storeUserAreas DISPATCHED',);
            return context.dispatch('postRequest', ['/user/' + parameters[0] + '/addareas/', parameters[1]]).then(data => {

                console.log('RESPONSE:', data);
                return data;
            })
        },

        getMetricGroupComments(context, period_id) {
            return context.dispatch('getRequest', '/comment/' + period_id).then(data => {
                store.commit('setMetricGroupComments', data);
                console.log('setMetricGroupComments DISPATCHED для периода', period_id);
            })
        },

        getReportComments(context, period_id) {
            return context.dispatch('getRequest', '/reportcomment/' + period_id).then(data => {
                store.commit('setReportComments', data);
                console.log('setReportpComments DISPATCHED для периода', period_id);
            })
        },

        storeMetricGroupComment(context, parameters) {
            console.log('parameters', parameters);
            console.log('storeMetricGroupComment DISPATCHED',);
            return context.dispatch('postRequest', ['/comment', parameters]).then(data => {

                console.log('RESPONSE:', data);
                return data;
            })
        },
        storeReportComment(context, parameters) {
            console.log('parameters', parameters);
            console.log('storeMetricGroupComment DISPATCHED',);
            return context.dispatch('postRequest', ['/reportcomment', parameters]).then(data => {

                console.log('RESPONSE:', data);
                return data;
            })
        },

        storeMetricGroupModeratorComment(context, parameters) {
            console.log('parameters', parameters);
            console.log('storeMetricGroupModeratorComment DISPATCHED',);
            return context.dispatch('postRequest', ['/moderatorcomment', parameters]).then(data => {

                console.log('RESPONSE:', data);
                return data;
            })
        },

        moderateMetricGroupComments(context, period_id) {
            console.log('parameters', period_id);
            console.log('moderateMetricGroupComments DISPATCHED',);

            return context.dispatch('getRequest', '/moderatecomments/' + period_id).then(
                store.dispatch('getMetricGroupComments', period_id)
            )
        },

        testModerateMetricGroupComments(context, period_id) {
            console.log('parameters', period_id);
            console.log('testModerateMetricGroupComments DISPATCHED',);

            return context.dispatch('getRequest', '/testmoderatecomments/' + period_id).then(
                store.dispatch('getMetricGroupComments', period_id)
            )
        },

        storeReportModeratorComment(context, parameters) {
            console.log('parameters', parameters);
            console.log('storeReportModeratorComment DISPATCHED',);
            return context.dispatch('postRequest', ['/moderatorreportcomment', parameters]).then(data => {

                console.log('RESPONSE:', data);
                return data;
            })
        },
        deleteUser(context, user_id) {
            return context.dispatch('deleteRequest', ['/user/' + user_id, null]).then(data => {
                console.log('parameters',);
                console.log('deleteUser DISPATCHED',);
                console.log('RESPONSE:', data);
                return data;
            })
        },
        getMetrics(context, project_id) {
            return context.dispatch('getRequest', '/metrics/' + project_id).then(data => {
                store.commit('setMetrics', data);
                console.log('getMetrics DISPATCHED for project ', project_id);
            })
        },
        getMetricGroups(context, project_id) {
            return context.dispatch('getRequest', '/metricgroups/' + project_id).then(data => {
                store.commit('setMetricGroups', data);
                console.log('getMetricGroups DISPATCHED for project ', project_id);
            })
        },

        updatePeriod(context, parameters) {
            return context.dispatch('postRequest', ['/period/' + parameters[0], parameters[1]]).then(data => {
                console.log('parameters', parameters);
                console.log('storePeriod DISPATCHED',);
                console.log('RESPONSE:', data);
                return data;
            })
        },
        createPeriod(context, parameters) {
            return context.dispatch('postRequest', ['/createperiod/', parameters[0]]).then(data => {
                console.log('parameters', parameters);
                console.log('createPeriod DISPATCHED',);
                console.log('RESPONSE:', data);
                return data;
            })
        },

        getTracks(context) {
            return context.dispatch('getRequest', '/tracks').then(data => {
                store.commit('setTracks', data);
                console.log('getTracks DISPATCHED');
                console.log('RESPONSE:', data);
            })
        },
        getReport(context, parameters) {
            let project_id = parameters[0];
            let area_id = parameters[1];
            let period_id = parameters[2];
            return context.dispatch('getFile', '/metricvalues/report/' + project_id + '/' + area_id + '/' + period_id).then(data => {
                console.log('getReport DISPATCHED');
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'report.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        },
        getExcel(context, parameters) {
            let project_id = parameters[0];
            return context.dispatch('getFile', '/exportExcel/' + project_id).then(data => {
                console.log('getExcel DISPATCHED');
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data_project' + project_id + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        },
        getCommentsExcel(context, parameters) {
            let project_id = parameters[0];
            return context.dispatch('getFile', '/exportCommentsExcel/' + project_id).then(data => {
                console.log('getCommentsExcel DISPATCHED');
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'comments_project' + project_id + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        },
        getPeriodStatistic(context, parameters) {
            let period_id = parameters[0];
            return context.dispatch('getRequest', '/statistics/' + period_id).then(data => {
                store.commit('setPeriodStatistic', data);
                console.log('getPeriodStatistic DISPATCHED');
                console.log('RESPONSE:', data);
            })
        },
        getMeteicGroupsCommentsStatistic(context, parameters) {
            let period_id = parameters[0];
            return context.dispatch('getRequest', '/metricgroupscommentsstatistic/' + period_id).then(data => {
                store.commit('setMetricGroupsCommentsStatistic', data);
                console.log('getMeteicGroupsCommentsStatistic DISPATCHED');
                console.log('RESPONSE:', data);
            })
        },

    }

})

export default store;
