<template>
  <Dialog
    v-model:visible="DV"
    :closable="true"
    :close-on-escape="true"
    :header="action=='create' ? 'Добавление отчетного периода' : 'Редактирование отчетного периода' +'  '+ period.name"
    :maximizable="true"
    :modal="true"
    :style="{width: '50vw'}"
  >
    <!--    :contentStyle="{height: '500px'}"-->

    <form class="p-fluid">
      <div class="field">
        <label for="start_at">Введите наименование отчетного периода:</label>
        <InputText
            v-model="name"
            class="w-full"
            placeholder="20xx - 20xx учебный год"
            type="text"
        />
      </div>
      <div class="field">
        <label for="start_at">Выберите дату начала ввода данных за период:</label>
        <Calendar
          v-model="start_at"
          :show-time="true"
          date-format="dd MM yy"
        />
      </div>

      <div class="field">
        <label for="end_at">Выберите дату окончания ввода данных за период:</label>
        <Calendar
          v-model="end_at"
          :show-time="true"
          date-format="dd MM yy"
        />
      </div>

      <div class="field">
        <label for="report_start_at">Выберите дату начала подачи отчетов за период:</label>
        <Calendar
          v-model="report_start_at"
          :show-time="true"
          date-format="dd MM yy"
        />
      </div>

      <div class="field">
        <label for="report_end_at">Выберите дату окончания подачи отчетов за период:</label>
        <Calendar
          v-model="report_end_at"
          :show-time="true"
          date-format="dd MM yy"
        />
      </div>

      <div class="field">
        <label for="areas">Метрики периода</label><br>
        <MultiSelect
            id="areas"
            v-model="selectedMetrics"
            :options="metrics"
            option-label="name"
            placeholder="Выберите метрику проекта"

        />
      </div>
    </form>

    <template #footer>
      <Button
        class="p-button-info"
        icon="pi pi-times"
        label="Отмена"
        @click="closeDialog"
      />
      <Button
        class="p-button-success"
        icon="pi pi-check"
        label="Сохранить"
        type="submit"
        @click="updatePeriod()"
      />
    </template>
  </Dialog>
</template>

<script>


import Button from "primevue/button";
import Dialog from "primevue/dialog";
import store from "@/state";
import {isEmpty} from "lodash/lang";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
// import moment from "moment";
import moment from 'moment-timezone'
// import metrics from "@/components/Metrics.vue";


export default {
  name: "EditPeriod",
  // components: {Button, Dialog, InputText, MultiSelect, InputMask, Password},
  components: {Button, Dialog, Calendar, InputText, MultiSelect},
  props: ['dialogVisible', 'period', 'action', 'project'],

  data() {
    return {
      DV: false,
      name: this.period.name,
      start_at: '',
      end_at: '',
      report_start_at: '',
      report_end_at: '',
      selectedMetrics: [],
      }
  },
  computed:{

    metrics() {
      console.log('metrics COMPUTED')
      return store.state.metrics.filter(e => e.project_id == this.project.id).map(e => {
        return {name: e.code + ' ' + e.name, value: e.id}
      });
    },

  },
  watch: {

    dialogVisible: function () {
      this.DV = true;
      this.start_at = this.period.start_at;
      this.end_at = this.period.end_at;
      this.report_start_at = this.period.start_at;
      this.report_end_at = this.period.end_at;
      this.start_at =  new Date(Date.parse(this.period.start_at + ' GMT-0000'));
      this.end_at =  new Date(Date.parse(this.period.end_at + ' GMT-0000'));
      this.report_start_at =  new Date(Date.parse(this.period.report_start_at + ' GMT-0000'));
      this.report_end_at =  new Date(Date.parse(this.period.report_end_at + ' GMT-0000'));
      this.selectedMetrics = store.state.metrics.filter(e => e.periods.map(x => {
        return x.id
      }).includes(this.period.id)).map(e => {
        return {name: e.code + ' ' + e.name, value: e.id}});


    },
  },
  methods: {

    closeDialog() {

      this.DV = false;


    },

    async updatePeriod() {

      let bodyParameters = {
        "name": this.name,
        "start_at": moment(this.start_at).tz("UTC").format(),
        "end_at": moment(this.end_at).tz("UTC").format(),
        "report_start_at": moment(this.report_start_at).tz("UTC").format(),
        "report_end_at": moment(this.report_end_at).tz("UTC").format(),

      };
      console.log('name= ', this.name);
      console.log('start_at= ', this.start_at);
      console.log('end_at= ', this.end_at);
      console.log('report_start_at= ', this.report_start_at);
      console.log('report_end_at= ', this.report_end_at);
      console.log('bodyParameters= ', bodyParameters);
      console.log('this.project= ', this.project);
      // const result = store.dispatch('storeMetricValue', bodyParameters);
      if (this.action == 'edit')
      {
        store.dispatch('updatePeriod', [this.period.id, bodyParameters]).then(data => {
          console.log('DATA^ ', data)
          if (!isEmpty(data.code))
            this.$toast.add({
              severity: 'error',
              summary: 'Ошибка сохранения периода',
              detail: data.message,
              life: 4000
            });
          else if (!isEmpty(data.data))
            this.$toast.add({
              severity: 'error',
              summary: 'Ошибка изменения периода',
              detail: {...data.data.errors, error: data.data.message},
              life: 4000
            });
          else {
            this.$toast.add({severity: 'success', summary: 'Период изменен', detail: data, life: 4000});
            store.dispatch('getPeriods');
            // this.resetForm();
            this.closeDialog();

          }

        })
      }
      else{
        bodyParameters['project_id'] = this.project.id
        // console.log('bodyParameters= ', bodyParameters);
        store.dispatch('createPeriod', [bodyParameters]).then(data => {
          console.log('DATA^ ', data)
          if (!isEmpty(data.code))
            this.$toast.add({
              severity: 'error',
              summary: 'Ошибка создания периода',
              detail: data.message,
              life: 4000
            });
          else if (!isEmpty(data.data))
            this.$toast.add({
              severity: 'error',
              summary: 'Ошибка создания периода',
              detail: {...data.data.errors, error: data.data.message},
              life: 4000
            });
          else {
            this.$toast.add({severity: 'success', summary: 'Период создан', detail: data, life: 4000});
            store.dispatch('getPeriods');
            // this.resetForm();
            this.closeDialog();

          }

        })
      }


    },

  },


}
</script>

<style scoped>

</style>