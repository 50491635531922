<template>
  <Toolbar>
    <template #start>
      <div class="align-items-center flex flex-column md:flex-row">
        <a href="/">
          <div class="flex  mr-4">
            <img
              alt="logo"
              height="80"
              src="../assets/yugra.png "
            >
          </div>
        </a>
        <div class="flex m-2 md:flex-row">
          <Button
            class="p-button-raised p-button-success md:flex-row"
            icon="pi pi-briefcase"
            icon-pos="left"
            label="Проекты"
            @click="goLink('/projects')"
          />
        </div>
        <div
          v-if="is_admin"
          class="flex m-2"
        >
          <Button
            class="p-button-raised p-button-success"
            icon="pi pi-users"
            icon-pos="left"
            label="Пользователи"
            @click="goLink('/users')"
          />
        </div>
      </div>
    </template>

    <template #end>
      {{ loading }}
      <div class="flex align-items-center ">
        <div class="mx-1 my-1">
          <Button
            icon="pi pi-question-circle"
            label="Помощь"
            class="p-button-outlined"
            style="width: 100%"
            @click="goLink('/docuser')"
          />
        </div>
        <div
          v-if="logged_in"
          class="m-2"
        >
          <SplitButton
            :model="items"
            class="p-button-raised"
            icon="pi pi-user"
            :label="login_as"
          />
        </div>

        <div
          v-else
          class="flex flex-column md:flex-row "
        >
          <InputText
            v-model="email"
            class="m-2"
            :class="{'p-invalid': wrong_password}"
            placeholder="E-mail"
          />
          <Password
            v-model="password"
            :feedback="false"
            class="m-2"
            :class="{'p-invalid': wrong_password}"
            placeholder="Пароль"
          />
          <Button
            class="p-button-raised m-2"
            @click="login"
          >
            Войти
          </Button>
        </div>
      </div>
    </template>
  </Toolbar>
  <Toast position="bottom-right" />
</template>



<script>

// import '../state.js'
// import "@/router";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Password from 'primevue/password';
import SplitButton from 'primevue/splitbutton';
import Toast from 'primevue/toast';
import Toolbar from "primevue/toolbar";


export default {
  name: "SignIn",
  components: {InputText, Button, Password, SplitButton, Toast, Toolbar},
  data() {
    return {

      email: '',
      password: '',
      items: [
        {
          label: 'Профиль',
          icon: 'pi pi-user',
          command: () => {
            this.$router.push('/userprofile')
          }
        },
        {
          label: 'Поддержка',
          icon: 'pi pi-envelope',
          command: () => {
            this.$router.push('/support')
          }
        },
        {
          label: 'Выйти',
          icon: 'pi pi-fw pi-sign-out',
          command: () => {
            this.logout()
          }
        },
      ]
    }
  },
  computed: {

    logged_in:
        function () {
          return this.$store.state.loggedIn
        },
    wrong_password:
        function () {
          return this.$store.state.wrongPassword
        },
    login_as:
        function () {
        if (this.$store.state.user) {
            return this.$store.state.user.email
          }
        return false
        },
    is_admin:
      function () {
        if (this.$store.state.user) {
          return this.$store.state.user.is_admin
        } else {
          return false
        }
      },
    loading:
      function (){
        return this.$store.state.loading
      }
  },
  watch: {
    wrong_password: function (wrongPassword) {
      if (wrongPassword) this.$toast.add({
        severity: 'error',
        summary: 'Ошибка аутентификации',
        detail: 'Неверный логин или пароль',
        life: 4000
      });

    },
    logged_in: function (loggedIn) {
      if (loggedIn) this.$toast.add({
        severity: 'success',
        summary: 'Успешная аутентификация',
        detail: 'Вы успешно вошли в систему',
        life: 4000
      });
      if (!loggedIn) this.$toast.add({
        severity: 'success',
        summary: 'Выход из системы',
        detail: 'Вы успешно вышли из системы',
        life: 4000
      });
    }
  },
  methods: {
    login() {
      this.$store.dispatch('auth', {login: this.email, password: this.password});
    },
    logout() {
      this.$store.dispatch('logout');
    },
    goLink(link) {
      this.$router.push(link)
    }
  },
}
</script>

<style scoped>


</style>