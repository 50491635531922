<template>
  <Card>
    <template #title>
      {{ selectedProject.name }}
    </template>
    <template #content>
      <div class="field">
        <label for="period">Отчетный период</label>
        <Dropdown
          id="period"
          v-model="selectedPeriod"
          :options="periods"
          class="w-full"
          option-label="name"
          placeholder="Выберите отчетный период"
          @change="periodSelected()"
        />
      </div>
      <Accordion
        v-if="data_visible"
        :multiple="true"
      >

        <AccordionTab
          v-for="(item) in user_areas"
          :key="item.id"
          :header="item.name"
        >
          <h3>Комментарии для отчета по группам показателей:</h3>
          <Accordion
              v-if="data_visible"
              :multiple="true"
          >
            <AccordionTab
                v-for="(item2) in metric_groups"
                :key="item2.id"
                :header="item2.code + '. ' + item2.name"

            >
              <Chip
                  v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.status == 3"
                  label="Отклонен модератором" style="background: red; color: white"
              />
              <Chip
                  v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.status == 1"
                  label="На проверке" style="background: blue; color: white"/>
              <Chip
                  v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.status == 2"
                  label="Одобрен" style="background: green; color: white"/>

              <p style="white-space: pre-wrap">
              {{metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.comment || 'ОТСУТСТВУЕТ'}}
              </p>

              <i v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.user">
                Комментарий введен пользователем: {{ metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id).user?.lastname + ' '
                  + metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id).user?.firstname + ' '
              + metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id).user?.surname + ' ' }}
              </i>
                <i v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.comment_at">
                Дата ввода: {{ format_date_ru(metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.comment_at) }}
              </i>
              <p/>
              <Button
                  :disabled="(!is_admin && !is_manager && !is_authenticated) || Date.now() < report_start_at || Date.now() > report_end_at"
                  class="p-button-outlined"
                  icon="pi pi-pencil"
                  label="Редактировать"
                  style="width: 200px"
                  @click="editMetricGroupComment(
                      {
                    area_id: item.id,
                    area_name: item.name,
                    // period_id: selectedPeriod.id,
                    metric_group_id: item2.id,
                    metric_group: item2.name,
                    report_description: item2.report_description,
                    old_comment:  this.metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.comment || null,

                })"
              />
              <div v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.moderator_comment">
                <p><b>Комментарий модератора:</b></p>
                <p style="white-space: pre-wrap">
                {{metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.moderator_comment}}
                </p>
                <p v-if="metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)"><i>
                  Модератор: {{ metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id).moderator?.lastname + ' '
                + metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id).moderator?.firstname + ' '
                + metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id).moderator?.surname + ' ' }}
                  Дата модерации: {{ format_date_ru(metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.moderate_at) }}</i>
                </p>
              </div>
              <div v-else>
                <br>
              </div>
              <Button
                  v-if="(is_admin || is_manager) "
                  class="p-button-info"
                  icon="pi pi-star"
                  label="Модерация"
                  style="width: 200px"
                  @click="editMetricGroupModeratorComment(
                      {
                    area_id: item.id,
                    area_name: item.name,
                    // period_id: selectedPeriod.id,
                    metric_group_id: item2.id,
                    metric_group: item2.name,
                    // report_description: item2.report_description,
                     old_comment:
                    metricGroupComments.find(e => e.area_id == item.id && e.metric_group_id == item2.id)?.moderator_comment || null,

                })"
              />

            </AccordionTab>
          </Accordion>
          <h3>Комментарий по отчету в целом:</h3>
          <Chip
              v-if="reportComments.find(e => e.area_id == item.id)?.status == 3"
              label="Отклонен модератором" style="background: red; color: white"
          />
          <Chip
              v-if="reportComments.find(e => e.area_id == item.id)?.status == 1"
              label="На проверке" style="background: blue; color: white"/>
          <Chip
              v-if="reportComments.find(e => e.area_id == item.id)?.status == 2"
              label="Одобрен" style="background: green; color: white"/>

          <p style="white-space: pre-wrap">
          {{reportComments.filter(e => e.area_id == item.id)[0]?
            reportComments.filter(e => e.area_id == item.id )[0].comment : 'ОТСУТСТВУЕТ'}}
          </p>
          <br>
          <i v-if="reportComments.find(e => e.area_id == item.id)?.user">
            Комментарий введен пользователем: {{ reportComments.find(e => e.area_id == item.id ).user?.lastname + ' '
          + reportComments.find(e => e.area_id == item.id ).user?.firstname + ' '
          + reportComments.find(e => e.area_id == item.id ).user?.surname + ' ' }}
          </i>
          <i v-if="reportComments.find(e => e.area_id == item.id )?.comment_at">
            Дата ввода: {{ format_date_ru(reportComments.find(e => e.area_id == item.id )?.comment_at) }}
          </i>
          <p/>
          <Button
              :disabled="(!is_admin && !is_manager && !is_authenticated) || Date.now() < report_start_at || Date.now() > report_end_at"
              class="p-button-outlined"
              icon="pi pi-pencil"
              label="Редактировать"
              style="width: 200px"
              @click="editReportComment(
                 {
                    area_id: item.id,
                    area_name: item.name,
                    report_description: selectedPeriod.report_description,
                    old_comment:  this.reportComments.find(e => e.area_id == item.id) ?
                    this.reportComments.find(e => e.area_id == item.id)?.comment : null,
                })"
          />
          <div v-if="reportComments.find(e => e.area_id == item.id)?.moderator_comment">
            <p><b>Комментарий модератора:</b></p>
            <p style="white-space: pre-wrap">
              {{reportComments.find(e => e.area_id == item.id)?.moderator_comment}}
            </p>
            <p v-if="reportComments.find(e => e.area_id == item.id)"><i>
              Модератор: {{ reportComments.find(e => e.area_id == item.id).moderator?.lastname + ' '
            + reportComments.find(e => e.area_id == item.id).moderator?.firstname + ' '
            + reportComments.find(e => e.area_id == item.id).moderator?.surname + ' ' }}
              Дата модерации: {{ format_date_ru(reportComments.find(e => e.area_id == item.id )?.moderate_at) }}</i>
            </p>
          </div>
          <div v-else>
            <br>
          </div>
          <Button
              v-if="(is_admin || is_manager) "
              class="p-button-info"
              icon="pi pi-star"
              label="Модерация"
              style="width: 200px"
              @click="editReportModeratorComment(
                      {
                    area_id: item.id,
                    area_name: item.name,
                    // period_id: selectedPeriod.id,
                    // metric_group_id: item2.id,
                    // metric_group: item2.name,
                    // report_description: item2.report_description,
                     old_comment:
                    reportComments.find(e => e.area_id == item.id )?.moderator_comment || null,

                })"
          />
          <p/>
          <div>
            <Button
                badge-class="p-badge-info"
                class="p-button-outlined"
                icon="pi pi-file-word"
                label="Скачать отчет"
                style="width: 100%"
                @click="getReport(item.id)"
            />
          </div>
        </AccordionTab>

      </Accordion>

    </template>
  </Card>
<!--  Вввод комментария по группе показателей -->
  <Dialog
      v-model:visible="dialog_visible"
      :closable="true"
      :close-on-escape="true"
      :content-style="{height: '600px'}"
      :header="header"
      :maximizable="true"
      :modal="true"
      :style="{width: '60vw'}"
  >

      <div class="field">
        <label
            for="newComment"
            style="white-space: pre-line">
          <h3><b>
            Краткие сведения по значениям показателей выбранной группы в сравнении с предыдущим периодом:
          </b></h3>
          <div
              v-for="(item3) in this.metric_values.filter(e => e.metric_group_id == this.selectedMetricGroupId)"
              :key="item3.id"
          >

            <b>{{item3.code}}. {{item3.name}}</b>
            <!-- Резюме данных по группе показателей -->



              <div>
                <!--              Блок предыдущий периода-->
                <div style="display: inline-block; box-sizing: border-box; padding: 5px; margin: 5px; width: 47%; background-color: #efefef; border-radius: 5px">
                  <p style="text-align: center"><b>{{ selectedPeriodPrevious?.name }}:</b></p>
<!--                  Если метрика не двоичная -->
                  <div v-if="!!!item3.is_binary">
                  <p>Показатель:
                    <b>{{metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId && e.period_id == selectedPeriodPrevious.id)?.value || "ОТСУТСТВУЕТ"}}
                    </b>
                  </p>
                  <p>Нормирующее значение:
                    <b>
                      {{
                        metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId
                            && e.period_id == selectedPeriodPrevious.id)?.norma_value ? metric_values.find(e =>
                            e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId && e.period_id ==
                            selectedPeriodPrevious.id)?.norma_value : 'ОТСУТСТВУЕТ'
                      }}
                    </b>
                  </p>
                  <p>Результат:
                    <b>
                      {{
                        metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId
                            && e.period_id == selectedPeriodPrevious.id)?.norma_value > 0 ?
                            (metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId
                                && e.period_id == selectedPeriodPrevious.id)?.value / metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId
                                && e.period_id == selectedPeriodPrevious.id)?.norma_value * 100).toFixed(2) + '%' : 'РАСЧЕТ НЕВОЗМОЖЕН'
                      }}
                    </b>
                  </p>
                  </div>
<!--                    Если метрика двоичная-->
                    <div v-else>
                      <div
                          v-if="metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId
                  && e.period_id == selectedPeriodPrevious?.id)?.value"
                      >
                        <b>
                          <div
                              v-if="!!metric_values.find(e => e.id == item3.id)?.metric_values.find(e => e.area_id == selectedAreaId
                      && e.period_id == selectedPeriodPrevious?.id)?.value"
                          >Да</div>
                          <div v-else>Нет</div>
                        </b>
                      </div>
                      <div v-else>
                        <b>ОТСУТСТВУЕТ</b>
                      </div>
                    </div>
                </div>

                  <!--    Блок текущий период            -->
                <div style="display: inline-block; box-sizing: border-box; padding: 5px; margin: 5px; width: 47%; background-color: #efefef; border-radius: 5px">
                  <p style="text-align: center"><b>{{ selectedPeriod?.name }}:</b></p>
                  <!--                  Если метрика не двоичная -->
                  <div v-if="!!!item3.is_binary">
                  <p>Показатель:
                    <b>
                      {{ item3.metric_values.find(e => e.area_id == selectedAreaId)?.value || "ОТСУТСТВУЕТ"}}
                    </b>
                  </p>
                  <p>Нормирующее значение:
                    <b>
                      {{
                        item3.metric_values.find(e => e.area_id == selectedAreaId)?.norma_value ? item3.metric_values.find(e => e.area_id == selectedAreaId)?.norma_value : 'ОТСУТСТВУЕТ'
                      }}
                    </b>
                  </p>
                  <p>Результат:
                    <b>
                      {{
                        (item3.metric_values.find(e => e.area_id == selectedAreaId)?.norma_value > 0) ?
                            (item3.metric_values.find(e => e.area_id == selectedAreaId)?.value / item3.metric_values.find(e => e.area_id == selectedAreaId)?.norma_value * 100).toFixed(2) + '%' : 'РАСЧЕТ НЕВОЗМОЖЕН'
                      }}
                    </b>
                  </p>
                  </div>
                  <!--                    Если метрика двоичная-->
                  <div v-else>
                    <div v-if="!!!item3.metric_values.find(e => e.area_id == selectedAreaId)?.value">
                      <b>ОТСУТСТВУЕТ</b>
                    </div>
                    <div v-else>
                      <b>
                        <div v-if="!!parseInt(item3.metric_values.find(e => e.area_id == selectedAreaId)?.value)">Да</div>
                        <div v-else>Нет</div>
                      </b>
                    </div>
                  </div>
                </div>
              </div>




          </div>
          <br>
          Введите комментарий для отчета по группе показателей согласно следующему плану: <br> {{ reportDescription }}<br>

        </label><br>

        <Textarea
            style="width: 100%"
            id="newComment"
            v-model="newComment"
            rows="20"
            locale="ru-RU"
            placeholder="Введите текст комментария (не менее 1000 знаков)"
            @change="valueEdited()"
        />
      </div>


    <template #footer>
      <Button
          :disabled="submit_disabled"
          icon="pi pi-check"
          icon-pos="left"
          label="Сохранить"
          @click="store_comment()"
      />
      <Button
          icon="pi pi-check"
          label="Закрыть"
          @click="closeDialog"
      />
    </template>
  </Dialog>
  <!--  Вввод комментария по отчету в целом -->
  <Dialog

      v-model:visible="dialog2_visible"
      :closable="true"
      :close-on-escape="true"
      :content-style="{height: '600px'}"
      :header="selectedAreaName"
      :maximizable="true"
      :modal="true"
      :style="{width: '60vw'}"
  >

    <div class="field">
      <label
          for="newComment"
          style="white-space: pre-line"
      >Введите комментарий <b>по отчету в целом</b> согласно следующему плану: <br> {{ reportDescription }}<br>


      </label><br>

      <Textarea
          style="width: 100%"
          id="newComment"
          v-model="newComment"
          rows="10"
          locale="ru-RU"
          placeholder="Введите текст комментария (не менее 1000 знаков)"
          @change="valueEdited()"
      />
    </div>


    <template #footer>
      <Button
          :disabled="submit_disabled"
          icon="pi pi-check"
          icon-pos="left"
          label="Сохранить"
          @click="store_report_comment()"
      />
      <Button
          icon="pi pi-check"
          label="Закрыть"
          @click="closeDialog"
      />
    </template>
  </Dialog>
  <!--  Вввод комментария МОДЕРАТОРА по группе показателей -->
  <Dialog
      v-model:visible="moderator_dialog_visible"
      :closable="true"
      :close-on-escape="true"
      :content-style="{height: '600px'}"
      :header="selectedAreaName"
      :maximizable="true"
      :modal="true"
      :style="{width: '60vw'}"
  >

    <div class="field">
      <label
          for="newComment"
          style="white-space: pre-line"
      >Введите комментарий модератора:<br>


      </label><br>

      <Textarea
          style="width: 100%"
          id="newComment"
          v-model="newComment"
          rows="10"
          locale="ru-RU"
          placeholder="Введите текст комментария "
          @change="valueEdited()"
      />
    </div>


    <template #footer>
      <Button
          :disabled="submit_disabled"
          icon="pi pi-check"
          icon-pos="left"
          class="p-button-success"
          label="Одобрить"
          @click="store_moderator_comment(2)"
      />
      <Button
          :disabled="submit_disabled"
          icon="pi pi-times"
          icon-pos="left"
          class="p-button-danger"
          label="Отклонить"
          @click="store_moderator_comment(3)"
      />
      <Button
          icon="pi pi-check"
          label="Закрыть"
          @click="closeDialog"
      />
    </template>
  </Dialog>
  <!--  Вввод комментария МОДЕРАТОРА по отчету в целом -->
  <Dialog
      v-model:visible="moderator_dialog2_visible"
      :closable="true"
      :close-on-escape="true"
      :content-style="{height: '600px'}"
      :header="selectedAreaName"
      :maximizable="true"
      :modal="true"
      :style="{width: '60vw'}"
  >

    <div class="field">
      <label
          for="newComment"
          style="white-space: pre-line"
      >Введите комментарий модератора к отчету в целом:<br>


      </label><br>

      <Textarea
          style="width: 100%"
          id="newComment"
          v-model="newComment"
          rows="10"
          locale="ru-RU"
          placeholder="Введите текст комментария "
          @change="valueEdited()"
      />
    </div>


    <template #footer>
      <Button
          :disabled="submit_disabled"
          icon="pi pi-check"
          icon-pos="left"
          class="p-button-success"
          label="Одобрить"
          @click="store_report_moderator_comment(2)"
      />
      <Button
          :disabled="submit_disabled"
          icon="pi pi-times"
          icon-pos="left"
          class="p-button-danger"
          label="Отклонить"
          @click="store_report_moderator_comment(3)"
      />
      <Button
          icon="pi pi-check"
          label="Закрыть"
          @click="closeDialog"
      />
    </template>
  </Dialog>


</template>

<script>
import Card from "primevue/card";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import store from "@/state";
import Dropdown from "primevue/dropdown";
// import {isEmpty} from "lodash/lang";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import {isEmpty} from "lodash/lang";
import Chip from "primevue/chip";
import moment from "moment-timezone";




export default {
  name: "ReportDownload",
  components: {Button, Card, Accordion, AccordionTab, Dropdown, Textarea, Dialog, Chip},
  data() {
    return {
      data_visible: false,
      dialog_visible: false,
      dialog2_visible: false,
      moderator_dialog_visible: false,
      moderator_dialog2_visible: false,
      submit_disabled: false,
      selectedPeriod: '',
      selectedAreaId: '',
      selectedAreaName: '',
      selectedMetricGroupId: '',
      selectedMetricGroup: '',
      oldComment: '',
      newComment: '',
      reportDescription: '',



    }
  },
  computed: {
    header() {
      return this.selectedAreaName + " - " + this.selectedMetricGroup;
    },

    is_authenticated() {
      return store.state.user != null;
    },

    is_admin() {
      return store.state.user.is_admin;
    },

    is_manager() {
      return store.state.user.is_manager;
    },

    selectedProject() {
          return this.$store.state.projects.find(e => e.id == this.$route.params.id);
        },
    periods() {
      console.log('periods COMPUTED')
      return store.state.periods.filter(e => e.project_id == this.selectedProject.id);
    },

    selectedPeriodPrevious() {
      return store.state.periods.find(e => e.project_id == this.selectedProject.id && e.id == this.selectedPeriod.prev_period_id);
    },

    user_areas() {
      return store.state.userAreas;
    },

    metricGroupComments() {
      console.log('store.state.metricGroupComments', store.state.metricGroupComments)
      return store.state.metricGroupComments;
    },

    reportComments() {
      console.log('store.state.reportComments', store.state.reportComments)
      return store.state.reportComments;
    },

    //
    // metrics(){
    //   return this.$store.state.metrics
    // },

    start_at() {
      // return new Date(new Date(Date.parse(this.selectedPeriod.start_at)).toLocaleString('en',
      //     {timeZone: 'Etc/UTC'}));
      return new Date(Date.parse(this.selectedPeriod.start_at + ' GMT-0000'));
    },

    end_at() {
      // return new Date(new Date(Date.parse(this.selectedPeriod.end_at)).toLocaleString('en',
      //     {timeZone: 'Etc/UTC'}));
      return new Date(Date.parse(this.selectedPeriod.end_at + ' GMT-0000'));
    },

    report_start_at() {
      // return new Date(new Date(Date.parse(this.selectedPeriod.start_at)).toLocaleString('en',
      //     {timeZone: 'Etc/UTC'}));
      return new Date(Date.parse(this.selectedPeriod.report_start_at + ' GMT-0000'));
    },

    report_end_at() {
      // return new Date(new Date(Date.parse(this.selectedPeriod.end_at)).toLocaleString('en',
      //     {timeZone: 'Etc/UTC'}));
      return new Date(Date.parse(this.selectedPeriod.report_end_at + ' GMT-0000'));
    },

    metric_groups() {
      // return store.state.metrics.filter(e => e.periods.map(x => {return x.id}).includes(period_id) && !!e.is_norma).length;
      console.log('Группы метрик которые есть в метриках=', store.state.metrics.map(x => {return x.metric_group_id}));
      console.log('ВСЕ Группы метрик=', store.state.metricGroups);
      var metricGroups = store.state.metricGroups.filter(e => { return store.state.metrics.filter(s => {return s.is_norma == 0 && s.periods.map(p => {return p.id}).includes( this.selectedPeriod.id)}).map(x => {return x.metric_group_id}).includes(e.id)});
      if (this.is_admin || this.is_manager)
        return metricGroups
      else
        return metricGroups.filter(m => {return m.is_regional != 1})
    },

    metric_values(){
      console.log('metric_values COMPUTED')
      return store.state.metricValues;
    },


  },
  methods: {
    periodSelected() {
      store.dispatch('getMetricGroupComments',this.selectedPeriod.id);
      store.dispatch('getReportComments',this.selectedPeriod.id);
      this.data_visible = true;
    },
    getReport(area_id) {
      store.dispatch('getReport', [this.selectedProject.id, area_id, this.selectedPeriod.id])
          .then(() => {
                console.log('Отчет загружен');
              }
          )
    },

    editMetricGroupComment({ area_id, area_name, metric_group_id, metric_group, report_description, old_comment})
    {
      this.dialog_visible = true;
      this.selectedAreaId = area_id;
      this.selectedAreaName = area_name;
      this.selectedMetricGroupId = metric_group_id;
      this.selectedMetricGroup = metric_group;
      this.oldComment = old_comment;
      this.newComment = old_comment;
      this.reportDescription = report_description;
    },
    editMetricGroupModeratorComment({ area_id, area_name, metric_group_id, metric_group, old_comment})
    {
      this.moderator_dialog_visible = true;
      this.selectedAreaId = area_id;
      this.selectedAreaName = area_name;
      this.selectedMetricGroupId = metric_group_id;
      this.selectedMetricGroup = metric_group;
      this.oldComment = old_comment;
      this.newComment = old_comment;
      console.log('this.oldComment = ', this.oldComment);
    },

    editReportModeratorComment({ area_id, area_name, old_comment})
    {
      this.moderator_dialog2_visible = true;
      this.selectedAreaId = area_id;
      this.selectedAreaName = area_name;
      // this.selectedMetricGroupId = metric_group_id;
      // this.selectedMetricGroup = metric_group;
      this.oldComment = old_comment;
      this.newComment = old_comment;
      console.log('this.oldComment = ', this.oldComment);
    },

    editReportComment({ area_id, area_name, report_description, old_comment})
    {
      console.log("old_comment = ", old_comment);
      this.dialog2_visible = true;
      this.selectedAreaId = area_id;
      this.selectedAreaName = area_name;
      this.oldComment = old_comment;
      this.newComment = old_comment;
      this.reportDescription = report_description;
    },
    closeDialog() {
      this.dialog_visible = false;
      this.dialog2_visible = false;
      this.moderator_dialog_visible = false;
      this.moderator_dialog2_visible = false;
      // this.submit_disabled = true;
    },
    valueEdited() {
      // if (this.newValue > 0)
      this.submit_disabled = false;
    },

    format_date_ru(datetime) {
      moment.tz.setDefault("UTC");
      return moment(datetime, '', 'ru').tz("Asia/Yekaterinburg").format('LLL');
    },


    async store_comment() {
      const bodyParameters = {
        "area_id": this.selectedAreaId,
        "metric_group_id": this.selectedMetricGroupId,
        "period_id": this.selectedPeriod.id,
        "comment": this.newComment.value == null ? this.newComment : this.newComment.value,

      };
      store.dispatch('storeMetricGroupComment', bodyParameters).then(data => {
        console.log('DATA^ ', data)
        if (!isEmpty(data.code))
          this.$toast.add({severity: 'error', summary: 'Ошибка добавления комментария', detail: data.message, life: 4000});
        else if (!isEmpty(data.data))
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка добавления комментария',
            detail: {...data.data.errors, error: data.data.message},
            life: 4000
          });
        else {
          this.$toast.add({severity: 'success', summary: 'Комментарий добавлен', detail: data, life: 4000});
          // store.dispatch('getMetricValues', this.selectedProject.id);
          this.closeDialog();
          store.dispatch('getMetricGroupComments',this.selectedPeriod.id);

        }

      })
    },

    async store_moderator_comment(status) {
      const bodyParameters = {
        "area_id": this.selectedAreaId,
        "metric_group_id": this.selectedMetricGroupId,
        "period_id": this.selectedPeriod.id,
        "comment": this.newComment.value == null ? this.newComment : this.newComment.value,
        "status": status

      };
      store.dispatch('storeMetricGroupModeratorComment', bodyParameters).then(data => {
        console.log('DATA^ ', data)
        if (!isEmpty(data.code))
          this.$toast.add({severity: 'error', summary: 'Ошибка добавления комментария', detail: data.message, life: 4000});
        else if (!isEmpty(data.data))
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка добавления комментария',
            detail: {...data.data.errors, error: data.data.message},
            life: 4000
          });
        else {
          this.$toast.add({severity: 'success', summary: 'Комментарий добавлен', detail: data, life: 4000});
          // store.dispatch('getMetricValues', this.selectedProject.id);
          this.closeDialog();
          store.dispatch('getMetricGroupComments',this.selectedPeriod.id);

        }

      })
    },

    async store_report_comment() {
      const bodyParameters = {
        "area_id": this.selectedAreaId,
        "period_id": this.selectedPeriod.id,
        "comment": this.newComment.value == null ? this.newComment : this.newComment.value,

      };
      store.dispatch('storeReportComment', bodyParameters).then(data => {
        console.log('DATA^ ', data)
        if (!isEmpty(data.code))
          this.$toast.add({severity: 'error', summary: 'Ошибка добавления комментария', detail: data.message, life: 4000});
        else if (!isEmpty(data.data))
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка добавления комментария',
            detail: {...data.data.errors, error: data.data.message},
            life: 4000
          });
        else {
          this.$toast.add({severity: 'success', summary: 'Комментарий добавлен', detail: data, life: 4000});
          // store.dispatch('getMetricValues', this.selectedProject.id);
          this.closeDialog();
          store.dispatch('getReportComments',this.selectedPeriod.id);

        }

      })
    },

    async store_report_moderator_comment(status) {
      const bodyParameters = {
        "area_id": this.selectedAreaId,
        // "metric_group_id": this.selectedMetricGroupId,
        "period_id": this.selectedPeriod.id,
        "comment": this.newComment.value == null ? this.newComment : this.newComment.value,
        "status": status

      };
      store.dispatch('storeReportModeratorComment', bodyParameters).then(data => {
        console.log('DATA^ ', data)
        if (!isEmpty(data.code))
          this.$toast.add({severity: 'error', summary: 'Ошибка добавления комментария', detail: data.message, life: 4000});
        else if (!isEmpty(data.data))
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка добавления комментария',
            detail: {...data.data.errors, error: data.data.message},
            life: 4000
          });
        else {
          this.$toast.add({severity: 'success', summary: 'Комментарий добавлен', detail: data, life: 4000});
          // store.dispatch('getMetricValues', this.selectedProject.id);
          this.closeDialog();
          store.dispatch('getReportComments',this.selectedPeriod.id);

        }

      })
    },

  },
  mounted() {
    // this.loading = true;
    this.selected
    store.dispatch('getAreas');
    store.dispatch('getMetricGroups',this.$route.params.id);
    store.dispatch('getMetrics', this.$route.params.id);
    store.dispatch('getAreas');
    store.dispatch('getMetricValues',this.$route.params.id);
  },
}
</script>

<style scoped>

</style>
