<template>
  <Panel v-if="project">
    <template #header>
      <h2> {{ project.name }}</h2>
    </template>
    {{ project.description }}
  </Panel>
  <Tree
    v-if="!loading"
    :value="tree"
  />
  <ProgressSpinner
    v-else
    stroke-width="1"
    class="flex justify-content-center"
    animation-duration="1s"
  />
</template>
<script>

import Panel from "primevue/panel";
import _ from "lodash";
import Tree from "primevue/tree";
import store from "@/state";
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "ProjectReport",
  components: {Panel, Tree, ProgressSpinner},
  props: ['id'],
  data() {
    return {
      loading: false,

    }
  },

  computed:
  {
    areas() {
      console.log('areas COMPUTED')
      return store.state.areas;
    },
    metrics() {
      console.log('metrics COMPUTED')
      return store.state.metrics;
    },
    periods() {
      console.log('periods COMPUTED')
      return store.state.periods;
    },
    metric_values(){
      console.log('metric_values COMPUTED')
      return store.state.metricValues;
    },
    project() {
      return this.$store.state.projects.find(e => e.id == this.$route.params.id)
    },

    tree()
    {
      let tree = [];
      console.log('metric_values watched для проекта: ',this.id);
      console.log('--------------metrics:------------------ ');
      console.log(JSON.stringify(this.metrics, null, 2));
      console.log('--------------metric_values:------------------ ');
      console.log(JSON.stringify(this.metric_values, null, 2)),
          // this.tree = Object.keys(this.metric_values.metrics).
          // map(x => JSON.parse('{"key": "'+x+'" , "label": "'+this.metric_values.metrics[x].name+'"}')),
          tree = Object.keys(this.metric_values).map(x => ({
            key: x,
            icon: "pi pi-fw pi-chart-line",
            label: this.metric_values[x].code + ' ' + this.metric_values[x].name,
            // children: [{key: 0, label: 'test'}]
            children:
                Object.keys(_.groupBy(this.metric_values[x].metric_values, (value) => value.area_id)).map((c, key) => ({
                  key: x + '-' + key,
                  area_id: c,
                  label: this.areas.filter(element => element.id == c)[0].name,
                  icon: "pi pi-fw pi-map-marker",
                  children:
                      this.metric_values[x].metric_values.filter(element => element.area_id == c).sort((a,b) => {if (a.period_id < b.period_id) {
                        return -1;}} ).map((y, key2) => ({
                        key: x + '-' + key + '-' + key2,
                        label: this.periods.filter(e => e.id == y.period_id)[0].name + ':  ' +
                            (this.metric_values[x].is_binary == '0' ?
                                (this.metric_values[x].is_norma == '1' ? y.value + ' (Является нормирующим значением для других метрик)'
                                    : Number(y.value / y.norma_value * 100).toFixed(2)+' % (Значение: ' + y.value +' Норма: ' + y.norma_value + ')')
                                : y.value > 0 ? "Да":"Нет"),
                        icon: "pi pi-fw pi-calendar",
                      })),
                })),
          })),
          console.log('----------------'),
          console.log(JSON.stringify(this.tree, null, 2)),
          console.log('-----------------')
          return tree;
    },
  },

  watch: {

    metric_values: function (){
      this.loading = false;
    }

    // metric_values: function () {
    //   console.log('metric_values watched для проекта: ',this.id);
    //   console.log('--------------metrics:------------------ ');
    //   console.log(JSON.stringify(this.metrics, null, 2));
    //   console.log('--------------metric_values:------------------ ');
    //   console.log(JSON.stringify(this.metric_values, null, 2)),
    //   // this.tree = Object.keys(this.metric_values.metrics).
    //   // map(x => JSON.parse('{"key": "'+x+'" , "label": "'+this.metric_values.metrics[x].name+'"}')),
    //   this.tree = Object.keys(this.metric_values).map(x => ({
    //     key: x,
    //     icon: "pi pi-fw pi-chart-line",
    //     label: this.metric_values[x].code + ' ' + this.metric_values[x].name,
    //     // children: [{key: 0, label: 'test'}]
    //     children:
    //         Object.keys(_.groupBy(this.metric_values[x].metric_values, (value) => value.area_id)).map((c, key) => ({
    //           key: x + '-' + key,
    //           area_id: c,
    //           label: this.areas.filter(element => element.id == c)[0].name,
    //           icon: "pi pi-fw pi-map-marker",
    //           children:
    //               this.metric_values[x].metric_values.filter(element => element.area_id == c).sort((a,b) => {if (a.period_id < b.period_id) {
    //                 return -1;}} ).map((y, key2) => ({
    //                 key: x + '-' + key + '-' + key2,
    //                 label: this.periods.filter(e => e.id == y.period_id)[0].name + ':  ' +
    //                     (this.metric_values[x].is_binary == '0' ?
    //                     (this.metric_values[x].is_norma == '1' ? y.value + ' (Является нормирующим значением для других метрик)'
    //                     : Number(y.value / y.norma_value * 100).toFixed(2)+' % (Значение: ' + y.value +' Норма: ' + y.norma_value + ')')
    //                         : y.value > 0 ? "Да":"Нет"),
    //                 icon: "pi pi-fw pi-calendar",
    //               })),
    //         })),
    //   })),
    //       console.log('----------------'),
    //       console.log(JSON.stringify(this.tree, null, 2)),
    //       console.log('-----------------')
    // },
  },

  mounted() {
    this.loading = true;
    store.dispatch('getAreas');
    store.dispatch('getMetrics',this.$route.params.id);
    store.dispatch('getMetricValues',this.$route.params.id);
  },


}
</script>
<style>
.layout{
  margin: 10px;
}
</style>